define("@trixie-html/core-session/repositories/biometrics-config", ["exports", "ember-object-data-model/repositories/repository", "@ember/service", "@ember/object", "@trixie-html/core-session/constants"], function (_exports, _repository, _service, _object, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const MAX_TIMES_SHOW = 3;
  const STORAGE_NAME = 'firstExperienceAccess';
  let ConfigurationRepository = (_class = class ConfigurationRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "userStorage", _descriptor, this);
    }
    async getBiometricsConfig() {
      const {
        count,
        lastShown
      } = await this._getCountAndLastShown();
      const {
        canEnrollDevice,
        methodToEnroll
      } = await this._canEnrollDevice();
      const customer = await this.provider.pull('customer', 'getContextualData');
      const hasEnrolledWithBiometry = _constants.ACCESS_METHODS[(0, _object.get)(customer, 'status.accessType')];
      const shouldEnrollBiometrics = !hasEnrolledWithBiometry && count < MAX_TIMES_SHOW && canEnrollDevice && methodToEnroll.length > 0;
      return this.createEntry('biometrics-config', 'BIOMETRICS_CONFIGURATION', {
        count,
        lastShown,
        shouldEnrollBiometrics,
        methodToEnroll
      });
    }
    async _saveBiometricsConfig(biometricsConfigurationModel) {
      await this.userStorage.setItem(STORAGE_NAME, biometricsConfigurationModel.toJSON());
      biometricsConfigurationModel.sync();
    }
    async _canEnrollDevice() {
      const accessMethods = await this.callRepository('access-method', 'findAll');
      const biometricMethods = accessMethods.rejectBy('isConsultive');
      const enabledMethod = biometricMethods === null || biometricMethods === void 0 ? void 0 : biometricMethods.findBy('isEnabled');
      const accessMethod = biometricMethods === null || biometricMethods === void 0 ? void 0 : biometricMethods.filterBy('isSupported');
      const canEnrollDevice = !enabledMethod;
      return {
        canEnrollDevice,
        methodToEnroll: accessMethod
      };
    }
    async _getCountAndLastShown() {
      const conditionsBiometricModal = await this.userStorage.getItem(STORAGE_NAME);
      if (!conditionsBiometricModal) {
        return {
          count: 0,
          lastShown: new Date().getTime()
        };
      }
      const lastShown = conditionsBiometricModal.lastShown;
      const today = new Date();
      const lastShownDate = new Date(lastShown);
      const lastShownPlusOneMonth = lastShownDate.setMonth(lastShownDate.getMonth() + 1);
      const resetCount = today.getTime() > lastShownPlusOneMonth;
      if (resetCount) {
        return {
          count: 0,
          lastShown: new Date().getTime()
        };
      }
      return {
        count: conditionsBiometricModal.count,
        lastShown
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "userStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ConfigurationRepository;
});