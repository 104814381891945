define("@woody-lite/addon-vertical-collection/components/virtual-scroll", ["exports", "@html-next/vertical-collection/components/vertical-collection/component", "@ember/service", "@ember/object", "@ember/test-waiters"], function (_exports, _component, _service, _object, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const waiter = (0, _testWaiters.buildWaiter)('@woody-lite/addon-vertical-collection:virtual-scroll-component');

  // https://developers.google.com/web/updates/2016/04/scroll-anchoring
  const SUPPORT_SCROLL_ANCHORING = CSS && CSS.supports && CSS.supports('(overflow-anchor: auto)');
  let VirtualScroll = (_dec = (0, _object.computed)('router.currentRouteName'), (_class = class VirtualScroll extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "memoryScroll", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "state", _descriptor3, this);
      _defineProperty(this, "restoreScroll", true);
      _defineProperty(this, "offsetKey", null);
      _defineProperty(this, "tagName", '');
    }
    get cacheEstimateHeight() {
      return this.state.next && this.memoryScroll[this.estimateHeightKey];
    }
    get estimateHeight() {
      return this.cacheEstimateHeight || this._originalEstimateHeight;
    }
    set estimateHeight(value) {
      this._originalEstimateHeight = value;
      this._estimateHeight = this.cacheEstimateHeight || this._originalEstimateHeight;
    }
    get estimateHeightKey() {
      const key = this.router.currentRouteName;
      return "virtual-estimate-height-".concat(key);
    }
    init() {
      super.init(...arguments);
      const checkFirstRender = this.checkFirstRender.bind(this);
      const currentRoute = this.router.currentRouteName;

      // Cannot reopen/override/break this instance so the dark magic begins now,
      // a proxy should to intercept calls to "_sendActions()" to send an action in the first render.
      //
      // Check the "Radar" class for more info:
      // https://github.com/html-next/vertical-collection/blob/master/addon/-private/data-view/radar/radar.js#L258
      this._radar = new Proxy(this._radar, {
        // Intercept the function "getter", it's called before the invocation
        get(target, key) {
          if (typeof target[key] === 'function' && key === '_sendActions') {
            checkFirstRender(target);
          }
          return Reflect.get(target, key);
        }
      });

      // Override this properties, the key should be the same during a transition
      // and always before the template gets rendered that's why it's defined also
      // in the component context
      this.offsetKey = currentRoute;
    }
    checkFirstRender() {
      const token = waiter.beginAsync();

      // Seen on the "Radar" code, it's the only way to check if the component has rendered something.
      if (this._radar._didReset || this._radar.firstVisibleIndex !== this._radar._prevFirstVisibleIndex) {
        // Internal flag to know if the component has been rendered
        if (!this._firstRender) {
          // Dispath an action, just for having another hook
          if (this.onFirstRender) {
            this.onFirstRender();
          }
          this.checkRestoreScroll();
          this._firstRender = true;
        }
      }
      waiter.endAsync(token);
    }
    checkRestoreScroll() {
      const token = waiter.beginAsync();
      if (this.restoreScroll) {
        const offset = this.memoryScroll[this.offsetKey];
        if (this.state.next && offset) {
          this.doScroll(offset);
          this.restoreEstimatedHeight();
        }
      }
      waiter.endAsync(token);
    }
    willDestroyElement() {
      try {
        const index = this._radar.firstVisibleIndex;
        const movementsHeight = this._radar.getOffsetForIndex(index);
        const estimateHeight = movementsHeight / index;
        if (isFinite(estimateHeight)) {
          this.memoryScroll[this.estimateHeightKey] = estimateHeight;
        }
      } catch {
        // it does not save in memory when firstVisibleIndex is not exist
        return;
      }
    }
    doScroll(top) {
      const scrolling = this._radar._scrollContainer;
      if (this._radar._scrollContainer) {
        if (scrolling.scrollTo) {
          scrolling.scrollTo(0, top);
        } else {
          scrolling.scrollTop = top;
        }
      }
    }
    restoreEstimatedHeight() {
      if (!SUPPORT_SCROLL_ANCHORING) {
        const offset = this.memoryScroll[this.offsetKey];
        const estimateHeight = this.memoryScroll[this.estimateHeightKey];
        const bufferSize = Math.max(Math.round(offset / estimateHeight), this.bufferSize);
        (0, _object.set)(this, 'bufferSize', bufferSize);
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "memoryScroll", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "state", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "estimateHeightKey", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "estimateHeightKey"), _class.prototype)), _class));
  _exports.default = VirtualScroll;
});