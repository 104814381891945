define("@trixie-html/addon-terms-and-conditions/enaxs/non-financial-services", ["exports", "@trixie-html/core-session/resources/client-enax", "@woody-mrs-potato/core-http/services/http"], function (_exports, _clientEnax, _http) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class NonFinancialServicesEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_ONLY);
      _defineProperty(this, "smc", {
        GET: 'SMC201800563',
        POST: 'SMC201800411'
      });
      _defineProperty(this, "endpoint", {
        GET: '/non-financial-services/v0/non-financial-services/',
        POST: '/non-financial-services/v0/non-financial-services'
      });
    }
    serialize(data) {
      if (this.method === 'GET') {
        return {
          targetUserId: this.currentSession.id,
          ...data
        };
      }
      return data;
    }
    async done() {
      const response = await super.done(...arguments);
      return response === null || response === void 0 ? void 0 : response.data;
    }
  }
  _exports.default = NonFinancialServicesEnax;
});