define("ember-storages/storages/memory", ["exports", "@ember/object", "@ember/object/proxy", "ember-storages/mixins/storage"], function (_exports, _object, _proxy, _storage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable consistent-return */
  /**
   * Storage base to implement memory or local storage functionality.
   *
   * @extends Ember.ObjectProxy
   * @uses StorageMixin
   */
  var _default = _proxy.default.extend(_storage.default, {
    /**
     * Initial content.
     *
     * @property initialContent
     * @type {Object}
     * @default Ember.Object
     */
    // "ember/no-arrow-function-computed-properties" conflicts with "prefer-arrow-callback"
    // eslint-disable-next-line ember/no-arrow-function-computed-properties
    initialContent: (0, _object.computed)(() => _object.default.create()),
    /**
     * Namespace to serialize keys.
     *
     * @property namespace
     * @type String
     * @default 'memory'
     */
    namespace: 'memory'
  });
  _exports.default = _default;
});