define("@trixie-html/addon-utils/helpers/copy-to-clipboard", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.copyToClipBoard = copyToClipBoard;
  _exports.default = void 0;
  function copyToClipBoard(text) {
    if (!text) {
      return false;
    }
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.setAttribute('readOnly', true);
    textarea.select();
    document.execCommand('SelectAll');
    document.execCommand('Copy');
    textarea.blur();
    document.body.removeChild(textarea);
    return true;
  }
  var _default = (0, _helper.helper)(copyToClipBoard);
  _exports.default = _default;
});