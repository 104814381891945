define("@trixie-html/core-semaas/repositories/semaas", ["exports", "ember-object-data-model/repositories/repository", "@ember/debug", "@woody-mrs-potato/core-http/utils/fetchify", "@ember/application", "ember-utils/utils/hash/guid", "@trixie-html/core-semaas/constants", "@ember/object"], function (_exports, _repository, _debug, _fetchify, _application, _guid, _constants, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SemaasRepository extends _repository.default {
    get config() {
      const {
        semaas
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return semaas;
    }
    async getSession(session) {
      (false && !(session && (0, _object.get)(session, 'tsec')) && (0, _debug.assert)('createSession() needs a valid session', session && (0, _object.get)(session, 'tsec')));
      const {
        accessToken,
        expiresAt
      } = await this._fetchToken(session);
      const {
        timestamp,
        offset
      } = await this._fetchTime(accessToken);

      // TODO: add model persistence.

      return this.createEntry('semaas', this.config.consumerId, {
        accessToken,
        expiresAt: expiresAt * _constants.SECONDS_TO_MILLISECONDS,
        timestamp,
        offset
      });
    }
    async _fetchToken(session) {
      const options = {
        url: "".concat(this.config.hosts.token, "/v1/Token"),
        method: 'GET',
        timeout: this.config.timeout,
        headers: {
          // TODO: use contactId here.
          ContactId: (0, _guid.default)(),
          // application.get('contactId'),
          'x-consumer-id': this.config.consumerId,
          'x-tsec-token': (0, _object.get)(session, 'tsec'),
          'x-validation-policy': this.config.validation
        }
      };
      const {
        data
      } = await new _fetchify.default(options).fetch();
      return data;
    }
    async _fetchTime(accessToken) {
      const options = {
        url: "".concat(this.config.hosts.rho, "/v1/ns/").concat(this.config.namespace, "/time"),
        method: 'GET',
        timeout: this.config.timeout,
        headers: {
          authorization: "Bearer ".concat(accessToken),
          'content-type': 'application/json; charset=utf-8'
        }
      };
      const start = performance.now();
      const {
        data
      } = await new _fetchify.default(options).fetch();
      const now = performance.now();
      const local = (performance.timing.navigationStart + now) * _constants.MILLISECONDS_TO_NANO;
      const rtt = (now - start) / 2 * _constants.MILLISECONDS_TO_NANO;
      const server = data.time + rtt;
      const offset = server - local;
      return {
        timestamp: server,
        offset
      };
    }
  }
  _exports.default = SemaasRepository;
});