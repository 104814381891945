define("@trixie-html/addon-tasks/models/kife-counter", ["exports", "ember-object-data-model/models/base", "@ember/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AnnouncementsModel extends _base.default {
    get announcementsAllCount() {
      return (0, _object.get)(this, 'announcementsCount') || 0;
    }
    get notificationsCount() {
      return (0, _object.get)(this, 'announcementsNotificationCount');
    }
    get taskCount() {
      return (0, _object.get)(this, 'announcementsTaskCount');
    }
  }
  _exports.default = AnnouncementsModel;
});