define("@trixie-html/addon-utils/helpers/capitalize-first", ["exports", "@ember/component/helper", "ember-utils/utils/strings/ucwordslower", "ember-utils/utils/strings/ucfirst", "@ember/array"], function (_exports, _helper, _ucwordslower, _ucfirst, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.capitalizeFirst = capitalizeFirst;
  _exports.default = void 0;
  function capitalizeFirst(params) {
    const value = (0, _array.isArray)(params) ? params[0] : params;
    return (0, _ucfirst.default)((0, _ucwordslower.default)(value)).trim();
  }
  var _default = _helper.default.helper(capitalizeFirst);
  _exports.default = _default;
});