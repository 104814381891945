define("ember-collector-dispatcher/services/dispatcher", ["exports", "@ember/service", "ember-collector-dispatcher/constants"], function (_exports, _service, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class Dispatcher extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "isRunning", false);
      _defineProperty(this, "isDispatching", false);
      _defineProperty(this, "maxTimeout", this.maxTimeout || _constants.MAX_TIMEOUT);
      _defineProperty(this, "maxConcurrent", this.maxConcurrent || _constants.MAX_CONCURRENT);
    }
    async start() {
      this.isRunning = true;
      this.waitAndSendMessage();
    }
    async stop() {
      this.isRunning = false;
    }
    waitAndSendMessage() {
      window.requestAnimationFrame(async () => {
        if (this.isRunning && !(this.isDestroying || this.isDestroyed)) {
          this.isDispatching = true;
          const items = await this.collector.shift(this.maxConcurrent);
          const hasItems = items && items.length > 0;
          if (hasItems) {
            const itemsReturned = await this.dispatch(items);
            if (itemsReturned && itemsReturned.length > 0) {
              await this.collector.unshift(...itemsReturned);
            }
          }
          this.isDispatching = false;
          this.waitAndSendMessage();
        }
      }, {
        timeout: this.maxTimeout
      });
    }
  }
  _exports.default = Dispatcher;
});