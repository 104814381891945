define("ember-google-maps/components/g-map", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component", "@glimmer/tracking", "@ember/object", "ember-google-maps/utils/helpers", "@ember/test-waiters"], function (_exports, _component, _templateFactory, _mapComponent, _tracking, _object, _helpers, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#let (component 'g-map/canvas' onCanvasReady=this.getCanvas) as |Canvas|}}
    {{#unless (has-block-params)}}
      <Canvas ...attributes />
    {{/unless}}
  
    {{yield (hash
      map=this.map
      canvas=Canvas
      marker=(component 'g-map/marker' getContext=this.getComponent)
      circle=(component 'g-map/circle' getContext=this.getComponent)
      rectangle=(component 'g-map/rectangle' getContext=this.getComponent)
      polyline=(component 'g-map/polyline' getContext=this.getComponent)
      polygon=(component 'g-map/polygon' getContext=this.getComponent)
      directions=(component 'g-map/directions' getContext=this.getComponent)
      control=(component 'g-map/control' getContext=this.getComponent)
      overlay=(component 'g-map/overlay' getContext=this.getComponent)
      infoWindow=(component 'g-map/info-window' getContext=this.getComponent)
      autocomplete=(component 'g-map/autocomplete' getContext=this.getComponent)
      customComponentTemplate=(component 'template' getContext=this.getComponent)
      )}}
  {{/let}}
  */
  {
    "id": "6vAWNIAk",
    "block": "[[[44,[[50,\"g-map/canvas\",0,null,[[\"onCanvasReady\"],[[30,0,[\"getCanvas\"]]]]]],[[[41,[51,[49,[30,3]]],[[[1,\"    \"],[8,[30,1],[[17,2]],null,null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[18,3,[[28,[37,5],null,[[\"map\",\"canvas\",\"marker\"],[[30,0,[\"map\"]],[30,1],[50,\"g-map/marker\",0,null,[[\"getContext\"],[[30,0,[\"getComponent\"]]]]]]]]]],[1,\"\\n\"]],[1]]]],[\"Canvas\",\"&attrs\",\"&default\"],false,[\"let\",\"component\",\"unless\",\"has-block-params\",\"yield\",\"hash\"]]",
    "moduleName": "ember-google-maps/components/g-map.hbs",
    "isStrictMode": false
  });
  function GMapPublicAPI(source) {
    return {
      get map() {
        return source.map;
      },
      get components() {
        return source.deprecatedPublicComponents;
      }
    };
  }
  let GMap = (_class = class GMap extends _mapComponent.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "canvas", _descriptor, this);
      _defineProperty(this, "components", new Set());
      _defineProperty(this, "deprecatedPublicComponents", {});
    }
    get publicAPI() {
      return GMapPublicAPI(this);
    }
    get map() {
      return this.mapComponent;
    }
    get newOptions() {
      var _this$options, _this$options$zoom;
      (_this$options$zoom = (_this$options = this.options).zoom) !== null && _this$options$zoom !== void 0 ? _this$options$zoom : _this$options.zoom = 15;
      if (!this.args.center) {
        this.options.center = (0, _helpers.toLatLng)(this.args.lat, this.args.lng);
      }
      return this.options;
    }

    // TODO: What if canvas is conditional? Render helpers? Promise? Force a
    // visible canvas?
    setup(options, events) {
      let map = new google.maps.Map(this.canvas, this.newOptions);
      this.addEventsToMapComponent(map, events, this.publicAPI);
      if (false /* DEBUG */) {
        this.pauseTestForIdle(map);
      }
      return map;
    }
    update(map) {
      map.setOptions(this.newOptions);
      if (false /* DEBUG */) {
        this.pauseTestForIdle(map);
      }
      return map;
    }

    // Pause tests until map is in an idle state.
    async pauseTestForIdle(map) {
      await new Promise(resolve => {
        google.maps.event.addListenerOnce(map, 'idle', () => resolve(map));
      });
    }
    getCanvas(canvas) {
      this.canvas = canvas;
    }
    getComponent(component) {
      let as = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'other';
      let storedComponent = {
        component,
        as
      };
      this.components.add(storedComponent);
      this.addToDeprecatedPublicComponents(storedComponent);
      return {
        context: this.publicAPI,
        remove: () => {
          this.components.delete(storedComponent);
          this.removeFromDeprecatedPublicComponents(storedComponent);
        }
      };
    }

    // TODO Deprecate access to this and replace with API methods.

    addToDeprecatedPublicComponents(_ref) {
      let {
        as,
        component
      } = _ref;
      if (!(as in this.deprecatedPublicComponents)) {
        this.deprecatedPublicComponents[as] = [];
      }
      this.deprecatedPublicComponents[as].push(component);
    }
    removeFromDeprecatedPublicComponents(_ref2) {
      let {
        as,
        component
      } = _ref2;
      let group = this.deprecatedPublicComponents[as];
      let index = group.indexOf(component);
      if (index > -1) {
        group.splice(index, 1);
      }

      // For backwards compatibility, we don't remove the groups when they're
      // empty.
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "canvas", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pauseTestForIdle", [_testWaiters.waitFor], Object.getOwnPropertyDescriptor(_class.prototype, "pauseTestForIdle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getCanvas", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getCanvas"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getComponent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getComponent"), _class.prototype)), _class);
  _exports.default = GMap;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GMap);
});