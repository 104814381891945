define("@trixie-html/core-session/decorators/session", ["exports", "@ember/application", "@ember/debug", "@trixie-html/core-session/utils/is-descriptor", "@trixie-html/core-session/utils/get-session"], function (_exports, _application, _debug, _isDescriptor, _getSession) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = session;
  function session(app, name) {
    (false && !(!(0, _isDescriptor.default)(...arguments) && app) && (0, _debug.assert)("The @".concat(name, " property needs an 'app' argument in decorator"), !(0, _isDescriptor.default)(...arguments) && app));
    return function (_0, _1, desc) {
      const {
        enumerable,
        configurable
      } = desc;
      return {
        enumerable,
        configurable,
        get() {
          const store = (0, _application.getOwner)(this).lookup('service:store');
          return (0, _getSession.default)(store, app);
        }
      };
    };
  }
});