define("ember-network-state/services/network", ["exports", "@ember/object", "@ember/service", "ember-network-state/constants", "@ember/object/evented", "@ember/runloop", "@ember/application", "@ember/array", "@glimmer/tracking"], function (_exports, _object, _service, _constants, _evented, _runloop, _application, _array, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let NetworkService = (_class = class NetworkService extends _service.default.extend(_evented.default) {
    get state() {
      return this._state;
    }
    get isOnline() {
      return this._state === _constants.STATES.ONLINE;
    }
    get isOffline() {
      return this._state === _constants.STATES.OFFLINE;
    }
    get isLimited() {
      return this._state === _constants.STATES.LIMITED;
    }
    get isReconnecting() {
      return !!this._controllers.length;
    }
    get hasTimer() {
      return !!this._timer;
    }
    get remaining() {
      const timestamp = this._timestamp;
      if (!timestamp) {
        return NaN;
      }
      const delta = timestamp - Date.now();
      return delta > 0 ? delta : 0;
    }
    reconnect() {
      this._clearTimer();
      this._reconnect();
    }
    setState(state) {
      if (state !== this._state) {
        this._clearTimer();
        this._state = state;
        this.trigger('change', state);
      }
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "lastReconnectDuration", _descriptor, this);
      _initializerDefineProperty(this, "lastReconnectStatus", _descriptor2, this);
      _initializerDefineProperty(this, "_times", _descriptor3, this);
      _initializerDefineProperty(this, "_timer", _descriptor4, this);
      _initializerDefineProperty(this, "_timestamp", _descriptor5, this);
      _initializerDefineProperty(this, "_state", _descriptor6, this);
      _initializerDefineProperty(this, "_config", _descriptor7, this);
      _initializerDefineProperty(this, "_nextDelay", _descriptor8, this);
      _defineProperty(this, "_controllers", (0, _array.A)());
      const appConfig = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const addonConfig = appConfig['network-state'] || {};
      const reconnect = Object.assign({}, _constants.CONFIG.reconnect, addonConfig.reconnect);
      this._config = {
        reconnect
      };
      if (this._connection) {
        this._connection.addEventListener('change', this._changeNetwork);
      } else {
        window.addEventListener('online', this._changeNetwork);
        window.addEventListener('offline', this._changeNetwork);
      }
      const onLine = window.navigator.onLine;
      this.setState(onLine ? _constants.STATES.ONLINE : _constants.STATES.OFFLINE);
      if (onLine) {
        this.reconnect();
      }
    }
    willDestroy() {
      super.willDestroy(...arguments);
      if (this._connection) {
        this._connection.removeEventListener('change', this._changeNetwork);
      }
      window.removeEventListener('online', this._changeNetwork);
      window.removeEventListener('offline', this._changeNetwork);
    }
    get _connection() {
      return window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
    }
    _clearTimer() {
      const timer = this._timer;
      if (timer) {
        (0, _runloop.cancel)(timer);
        this._timer = undefined;
      }
      this._nextDelay = undefined;
      this._times = 0;
      this._timestamp = undefined;
    }
    _changeNetwork() {
      const onLine = window.navigator.onLine;

      /* istanbul ignore else */
      if (this._connection) {
        this.trigger('connection-change', this._connection);
      }
      if (!onLine) {
        this.setState(_constants.STATES.OFFLINE);
      } else {
        this.reconnect();
      }
    }
    _fetch() {
      return window.fetch(...arguments);
    }
    async _reconnect() {
      const {
        reconnect
      } = this._config;
      const controller = new AbortController();

      // Cancel all ongoing controllers.
      this._abortControllers();
      // Push new controller.
      this._controllers.pushObject(controller);
      const timeout = (0, _runloop.later)(controller, 'abort', reconnect.timeout);
      const start = performance.now();
      let status = 0;
      try {
        const response = await this._fetch(reconnect.path, {
          method: 'HEAD',
          cache: 'no-store',
          signal: controller.signal,
          headers: {
            'cache-control': 'no-cache'
          }
        });
        if (!this.isDestroyed) {
          this._controllers.removeObject(controller);
          status = response.status;
          this.setState(_constants.STATES.ONLINE);
        }
      } catch (e) {
        this._controllers.removeObject(controller);
        if (!this.isDestroyed && !this.isReconnecting) {
          this._handleError();
        }
      } finally {
        (0, _runloop.cancel)(timeout);
        if (!this.isDestroyed && !this.isReconnecting) {
          this.lastReconnectStatus = status;
          this.lastReconnectDuration = performance.now() - start;
        }
      }
    }
    _abortControllers() {
      const controllers = [...this._controllers];
      controllers.forEach(controller => {
        controller.abort();
      });
      this._controllers.removeObjects(controllers);
    }
    _handleError() {
      const {
        reconnect
      } = this._config;
      const onLine = window.navigator.onLine;
      if (onLine) {
        this.setState(_constants.STATES.LIMITED);
        if (reconnect.auto) {
          this._times++;
          this._delayReconnect();
        }
      } else {
        this.setState(_constants.STATES.OFFLINE);
      }
    }
    _delayReconnect() {
      const {
        reconnect
      } = this._config;
      const _nextDelay = this._nextDelay;
      const delay = _nextDelay === undefined ? reconnect.delay : _nextDelay;
      const times = this._times;
      let nextDelay = delay * reconnect.multiplier;
      if (reconnect.maxTimes > -1 && times >= reconnect.maxTimes) {
        this._clearTimer();
        this.setState(_constants.STATES.LIMITED);
        return;
      }
      if (nextDelay > reconnect.maxDelay) {
        nextDelay = reconnect.maxDelay;
      }
      const timer = (0, _runloop.later)(this, '_reconnect', delay);
      this._nextDelay = nextDelay;
      this._timestamp = Date.now() + delay;
      this._timer = timer;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "lastReconnectDuration", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lastReconnectStatus", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "_times", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_timer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_timestamp", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_state", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return window.navigator.onLine ? _constants.STATES.ONLINE : _constants.STATES.OFFLINE;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_config", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_nextDelay", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_changeNetwork", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "_changeNetwork"), _class.prototype)), _class);
  _exports.default = NetworkService;
});