define("ember-google-maps/components/g-map/typical-map-component", ["exports", "ember-google-maps/components/g-map/map-component"], function (_exports, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TypicalMapComponent extends _mapComponent.default {
    get newOptions() {
      return this.options;
    }
    setup() {
      let mapComponent = this.newMapComponent(this.newOptions);
      this.addEventsToMapComponent(mapComponent, this.events, this.publicAPI);
      mapComponent.setMap(this.map);
      return mapComponent;
    }
    update(mapComponent) {
      var _mapComponent$setOpti;
      mapComponent === null || mapComponent === void 0 ? void 0 : (_mapComponent$setOpti = mapComponent.setOptions) === null || _mapComponent$setOpti === void 0 ? void 0 : _mapComponent$setOpti.call(mapComponent, this.newOptions);
      return mapComponent;
    }
  }
  _exports.default = TypicalMapComponent;
});