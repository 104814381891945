define("@trixie-html/core-traceability/utils/get-base-object", ["exports", "ember-copy", "@trixie-html/core-traceability/constants"], function (_exports, _emberCopy, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getBaseObject;
  function getBaseObject() {
    return (0, _emberCopy.copy)(_constants.BASE_OBJECT, true);
  }
});