define("@trixie-html/core-traceability/instance-initializers/tealium-route-tracking", ["exports", "@ember/application/instance", "@ember/engine/instance", "ember-utils/utils/primitive/merge", "@trixie-html/core-traceability/utils/get-page-levels", "@trixie-html/core-traceability/utils/page-name", "@trixie-html/core-traceability/utils/tealium-context", "@trixie-html/core-traceability/events/view", "@trixie-html/core-traceability/utils/is-adapter-enabled"], function (_exports, _instance, _instance2, _merge, _getPageLevels, _pageName, _tealiumContext, _view, _isAdapterEnabled) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  const instances = {};
  function lookupRoute(routeName) {
    let owner = instances['host-app'];
    const engineName = Object.keys(instances).find(key => key !== 'host-app' && routeName.startsWith(key));
    if (engineName) {
      owner = instances[engineName];
      routeName = routeName.slice(engineName.length + 1) || 'application';
    }
    return owner.lookup("route:".concat(routeName), {
      instantiate: false
    });
  }
  async function getRouteTrackingData(transition) {
    let node = transition.to;
    let route;
    let routeHasTrackingDataMethod = false;
    do {
      var _route;
      route = lookupRoute(node.name);
      node = node.parent;
      routeHasTrackingDataMethod = typeof ((_route = route) === null || _route === void 0 ? void 0 : _route.trackingData) === 'function';
    } while (node && !routeHasTrackingDataMethod);
    if (routeHasTrackingDataMethod) {
      return await route.trackingData();
    }
    return null;
  }
  function getPageName(metrics, name) {
    const area = (0, _tealiumContext.getContext)(metrics, 'session.page.pageInfo.area');
    return (0, _pageName.default)(area, name);
  }
  function completeRouteTrackingData(metrics, baseData, fullRouteName) {
    const levels = (0, _getPageLevels.default)(fullRouteName);
    const pageInfo = {
      pageName: getPageName(metrics, fullRouteName)
    };
    const obj = {
      page: {
        pageInfo
      }
    };
    levels.slice(0, _getPageLevels.MAX_PAGE_LEVELS).forEach((level, index) => {
      pageInfo["level".concat(index + 1)] = level;
    });
    return (0, _merge.default)(obj, baseData);
  }
  function setupRouteListener(metrics, router, scheduler) {
    const callback = scheduler.scheduleOnce.bind(scheduler, this, async transition => {
      const routeTrackingData = await getRouteTrackingData(transition);
      const trackingData = completeRouteTrackingData(metrics, routeTrackingData, transition.to.name);
      (0, _tealiumContext.setContext)(metrics, 'currentRoute');
      metrics.trackEvent('tealium', new _view.default(trackingData));

      // `context.currentRoute` will only work if you share the `metrics` service between engine & app
      (0, _tealiumContext.setContext)(metrics, 'currentRoute', trackingData);
    });
    router.on('routeDidChange', transition => {
      if (!transition.isAborted) {
        callback(transition);
      }
    });
  }
  function initialize(instance) {
    const config = instance.resolveRegistration('config:environment');
    if ((0, _isAdapterEnabled.default)(config)) {
      // This condition prevents initializing the adapter
      const metrics = instance.lookup('service:metrics');
      if (metrics) {
        if (instance instanceof _instance.default) {
          const router = instance.lookup('service:router');
          const scheduler = instance.lookup('service:scheduler');
          instances['host-app'] = instance;
          setupRouteListener(metrics, router, scheduler);
        } else if (instance instanceof _instance2.default && instance.mountPoint) {
          instances[instance.mountPoint] = instance;
        }
      }
    }
  }
  var _default = {
    name: 'tealium/route-tracking',
    initialize
  };
  _exports.default = _default;
});