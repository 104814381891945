define("@trixie-html/addon-terms-and-conditions/repositories/terms-and-conditions", ["exports", "ember-object-data-model/repositories/repository", "@ember/service", "@ember/object", "@trixie-html/core-session/decorators/session", "@ember/application", "@glimmer/tracking"], function (_exports, _repository, _service, _object, _session, _application, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const TERMS_UNIQ_ID = 'T&C';
  const DOCUMENT_UNIQ_ID = "".concat(TERMS_UNIQ_ID, "-DOC");
  let TermsAndConditionsRepository = (_dec = (0, _session.default)('client'), (_class = class TermsAndConditionsRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "enax", _descriptor, this);
      _initializerDefineProperty(this, "veil", _descriptor2, this);
      _initializerDefineProperty(this, "gam", _descriptor3, this);
      _initializerDefineProperty(this, "provider", _descriptor4, this);
      _initializerDefineProperty(this, "currentSession", _descriptor5, this);
      _initializerDefineProperty(this, "nonFinancialProductId", _descriptor6, this);
      this.nonFinancialProductId = (0, _application.getOwner)(this).resolveRegistration('config:environment')['engine-terms-and-conditions'].nonFinancialProductId;
    }
    getTermsAndConditions() {
      return this.createEntry('terms-and-conditions/terms-and-conditions', TERMS_UNIQ_ID, {
        documentId: this.nonFinancialProductId
      });
    }
    async _staticDocument() {
      const tycNode = await this._getTermsAndConditionsTexts();
      const urlDocument = tycNode.urlDocument;
      return this.createEntry('terms-and-conditions/document', "".concat(DOCUMENT_UNIQ_ID, "_staticDocument"), {
        urlDocument
      });
    }
    async _getDocument() {
      var _response, _response2, _response2$headers;
      this.veil.add();
      const nonFinancialProductId = (0, _application.getOwner)(this).resolveRegistration('config:environment')['engine-terms-and-conditions'].nonFinancialProductId;
      let response = null;
      try {
        response = await this.enax.POST('validate-non-financial-service', null, {
          evidenceId: nonFinancialProductId
        });
      } catch {
        // Noop
      } finally {
        this.veil.remove();
      }
      const tycNode = await this._getTermsAndConditionsTexts();
      const urlDocument = tycNode.urlDocument;
      const data = (_response = response) === null || _response === void 0 ? void 0 : _response.data;
      const cuf = (_response2 = response) === null || _response2 === void 0 ? void 0 : (_response2$headers = _response2.headers) === null || _response2$headers === void 0 ? void 0 : _response2$headers.get('cuf');
      return this.createEntry('terms-and-conditions/document', DOCUMENT_UNIQ_ID, {
        cuf,
        title: tycNode.title,
        legalConditions: tycNode.legalConditions,
        text: tycNode.content,
        urlDocument,
        ...data
      });
    }
    async _getTermsAndConditionsTexts() {
      const customer = await this.provider.pull('customer', 'getContextualData');
      const application = await this.provider.pull('application', 'getApplication');
      const response = await this.gam.POST('tyc-texts', {}, {
        params: {
          expand: 'tycNode'
        },
        session: this.currentSession,
        customer,
        previousAppVersion: (0, _object.get)(application, 'previousVersion')
      });
      const data = response.data;
      const tycNode = data.tycNode;
      return tycNode;
    }
    _signDocument(termsDocument) {
      return this.enax.POST('non-financial-services', {
        nonFinancialProduct: (0, _object.get)(termsDocument, 'nonFinancialProduct'),
        targetUserId: this.currentSession.id
      }, {
        headers: {
          cuf: (0, _object.get)(termsDocument, 'cuf')
        }
      });
    }
    _clearDocument(termsDocument) {
      const {
        id,
        version
      } = (0, _object.get)(termsDocument, 'nonFinancialProduct');
      return this.enax.POST('unsubscribe-non-financial-service', null, {
        headers: {
          cuf: (0, _object.get)(termsDocument, 'cuf')
        },
        evidenceId: id,
        version,
        reason: '3'
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "gam", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "nonFinancialProductId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TermsAndConditionsRepository;
});