define("memory-scroll/components/scroll-to", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const FIRST_RUN = function () {};
  const {
    Component
  } = _ember.default;
  var _default = Component.extend({
    init() {
      this._lastKey = FIRST_RUN;
      this._super(...arguments);
    },
    didRender() {
      this._super(...arguments);
      let key = this.get('key');
      if (key !== this._lastKey) {
        this._lastKey = key;
        (document.scrollingElement || document.documentElement).scrollTop = this.get('position') || 0;
      }
    }
  });
  _exports.default = _default;
});