define("@woody-mrs-potato/core-transitions/transitions", ["exports", "@ember/application", "@ember/object", "ember"], function (_exports, _application, _object, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CAROUSEL_ANIMATION_DURATION = void 0;
  _exports.default = addTransitions;
  const CAROUSEL_ANIMATION_DURATION = 300;
  _exports.CAROUSEL_ANIMATION_DURATION = CAROUSEL_ANIMATION_DURATION;
  const DURATION_VALUE = 400;
  function checkTransition(state) {
    const currentIndex = (0, _object.get)(state, 'current.index');
    const lastIndex = (0, _object.get)(state, 'last.index');
    return currentIndex - lastIndex;
  }
  function transitionBuilder(context, router, liquidFire) {
    return (animation, options) => {
      context.transition(context.withinRoute(() => getQueryParam(router, 'animation') === animation), context.use('withEvent', liquidFire, animation, options));
    };
  }
  function getQueryParam(router, queryParamName) {
    return (0, _object.get)(router, "_router.currentState.routerJsState.fullQueryParams.".concat(queryParamName));
  }
  function getDuration() {
    // istanbul ignore else: this duration is to productive environment, imposible to test it.
    if (_ember.default.testing) {
      return 0;
    }

    // istanbul ignore next: this duration is to productive environment, imposible to test it.
    return DURATION_VALUE;
  }
  function createTransitionMap() {
    const namespace = (0, _application.getOwner)(this.map);
    const queryParams = new URLSearchParams(document.location.search);
    const duration = getDuration();

    // istanbul ignore if: this function is to deactivate into QE environment
    if (!namespace || (0, _object.get)(queryParams, 'animations') === 'false') {
      return;
    }
    const container = namespace.__container__;
    const liquidFire = container.lookup('service:liquid-fire');
    const router = container.lookup('service:router');
    const state = container.lookup('service:state');
    this.transition(this.withinRoute(() => checkTransition(state) === 0), this.use('withEvent', liquidFire, 'fadeOut', {
      duration
    }));
    this.transition(this.withinRoute(() => checkTransition(state) > 0), this.use('withEvent', liquidFire, 'toLeft', {
      duration
    }));
    this.transition(this.withinRoute(() => checkTransition(state) < 0), this.use('withEvent', liquidFire, 'toRight', {
      duration
    }));
    this.transition(this.fromRoute(route => route.match(/index/)), this.toRoute(route => route.match(/(confirmation|conditions)/)), this.use('withEvent', liquidFire, 'toLeft', {
      duration
    }));
    this.transition(this.fromRoute(route => route.match(/(confirmation|conditions)/)), this.toRoute(route => route.match(/index/)), this.use('withEvent', liquidFire, 'toRight', {
      duration
    }));
    this.transition(this.withinRoute((fromRoute, toRoute) => {
      // istanbul ignore next: cannot use the "visit" helper to transition into the same route
      const betweenEngines = fromRoute === 'application' && toRoute === 'application';
      return !betweenEngines && fromRoute === toRoute;
    }), this.use('withEvent', liquidFire, 'fadeOut', {
      duration
    }));
    const animations = ['toLeft', 'toRight', 'fade', 'fadeOut'];
    const createTransition = transitionBuilder(this, router, liquidFire);
    animations.forEach(animation => {
      createTransition(animation, {
        duration
      });
    });
    this.transition(this.onInitialRender(), this.use('withEvent', liquidFire, 'none'));
    this.transition(this.hasClass('carouselAnimation'),
    // in
    this.toValue(true), this.use('toLeft'),
    // out
    this.reverse('scaleFade', {
      duration: CAROUSEL_ANIMATION_DURATION
    }));
  }
  function addTransitions(context) {
    createTransitionMap.call(context);
  }
});