define("@trixie-html/core-session/repositories/token-software", ["exports", "ember-object-data-model/repositories/repository", "@ember/service", "@ember/object", "@trixie-html/core-session/constants", "@trixie-html/core-session/decorators/session"], function (_exports, _repository, _service, _object, _constants, _session) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let TokenSoftwareRepository = (_dec = (0, _session.default)('client'), (_class = class TokenSoftwareRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "slinky", _descriptor, this);
      _initializerDefineProperty(this, "enax", _descriptor2, this);
      _initializerDefineProperty(this, "provider", _descriptor3, this);
      _initializerDefineProperty(this, "nativeStorage", _descriptor4, this);
      _initializerDefineProperty(this, "currentSession", _descriptor5, this);
    }
    async getTokenSoftware() {
      const capabilities = await this.provider.pull('device', 'getCapabilities');
      const customerId = this.currentSession.id;
      const model = this.createEntry('token-software', customerId, {
        hasSecuredLockScreen: (0, _object.get)(capabilities, 'hasSecuredLockScreen'),
        isDeviceRooted: (0, _object.get)(capabilities, 'isRooted'),
        supportsBiometrics: Boolean((0, _object.get)(capabilities, 'supportsBiometrics') || (0, _object.get)(capabilities, 'supportsTouchId') || (0, _object.get)(capabilities, 'supportsFaceId')),
        hasBiometricsRegistered: Boolean((0, _object.get)(capabilities, 'hasBiometricsRegistered') || (0, _object.get)(capabilities, 'hasTouchIdRegistered') || (0, _object.get)(capabilities, 'hasFaceIdRegistered'))
      });
      await this._setUserCustomizationData(await (0, _object.get)(model, 'isActive'));
      return model;
    }
    async getDeviceToken(tokenSoftware) {
      const remoteToken = await (0, _object.get)(tokenSoftware, 'remoteToken');
      let hasDeviceToken = false;
      try {
        const {
          tokens = []
        } = await this.slinky.send('EasySign.getTokens');
        hasDeviceToken = tokens.includes((0, _object.get)(remoteToken, 'id'));
      } catch {
        // noop.
      }
      return this.createEntry('device-token-software', remoteToken.id, {
        status: {
          id: hasDeviceToken ? _constants.STATUS.ACTIVE : _constants.STATUS.UNSUBSCRIBED
        }
      });
    }
    async getRemoteToken(tokenSoftware) {
      var _remoteToken;
      const deviceId = await (0, _object.get)(tokenSoftware, 'deviceId');
      const {
        data: remoteTokens
      } = await this.enax.GET('software-tokens');
      let remoteToken = remoteTokens.find(token => token.deviceId === deviceId);
      if (!remoteToken) {
        remoteToken = remoteTokens.find(token => token.status.id === _constants.STATUS.ACTIVE);
        if (remoteToken) {
          remoteToken.status.id = _constants.STATUS.OTHER_ACTIVE;
        }
      }
      return this.createEntry('remote-token-software', ((_remoteToken = remoteToken) === null || _remoteToken === void 0 ? void 0 : _remoteToken.id) || deviceId, {
        status: {
          id: _constants.STATUS.UNSUBSCRIBED
        },
        ...remoteToken
      });
    }
    async deleteDeviceToken(deviceToken) {
      const serial = (0, _object.get)(deviceToken, 'id');
      await this.slinky.send('EasySign.deleteToken', {
        serial
      });
      await this._setUserCustomizationData(false);
      return (0, _object.get)(deviceToken, 'parent.deviceToken').reload();
    }
    async deleteRemoteToken(remoteToken) {
      const id = (0, _object.get)(remoteToken, 'id');
      const deleteST = await this.enax.DELETE('delete-software-token', null, {
        id
      });
      await (0, _object.get)(remoteToken, 'parent.remoteToken').reload();
      return Boolean(deleteST);
    }
    async _setUserCustomizationData(hasBiometricsSignActivated) {
      await this.nativeStorage.setItem('hasBiometricsSignActivated', hasBiometricsSignActivated);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "enax", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "nativeStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TokenSoftwareRepository;
});