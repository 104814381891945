define("ember-storages/storages/cache", ["exports", "ember-storages/storages/local"], function (_exports, _local) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Storage base to implement memory or local storage functionality.
   *
   * @extends LocalStorage
   */
  var _default = _local.default.extend({
    /**
     * Namespace to serialize keys.
     *
     * @property namespace
     * @type String
     * @default 'cache'
     */
    namespace: 'cache'
  });
  _exports.default = _default;
});