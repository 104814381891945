define("@trixie-html/core-semaas/services/semaas-otp-collector", ["exports", "ember-collector-dispatcher/services/collector"], function (_exports, _collector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class SemaasOtpCollectorService extends _collector.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "adapters", [['indexed-db', {
        database: 'semaas-otp'
      }], ['local-storage', {
        key: 'semaas-otp'
      }], 'memory']);
    }
  }
  _exports.default = SemaasOtpCollectorService;
});