define("@trixie-html/core-traceability/utils/page-name", ["exports", "@trixie-html/core-traceability/utils/get-page-levels"], function (_exports, _getPageLevels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BBVA_PAGE_SEGMENT = void 0;
  _exports.default = pageName;
  const BBVA_PAGE_SEGMENT = 'particulares';
  _exports.BBVA_PAGE_SEGMENT = BBVA_PAGE_SEGMENT;
  function pageName(area) {
    for (var _len = arguments.length, fullPageNames = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      fullPageNames[_key - 1] = arguments[_key];
    }
    const pageNames = fullPageNames.map(_getPageLevels.default).flat();
    return [area, BBVA_PAGE_SEGMENT, ...pageNames].join(':');
  }
});