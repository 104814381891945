define("@trixie-html/addon-terms-and-conditions/models/terms-and-conditions/document", ["exports", "ember-object-data-model/models/base", "ember-object-data-model/decorators/belongs-to", "@ember/service", "@ember/object"], function (_exports, _base, _belongsTo, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let TermsAndConditionsModel = (_dec = (0, _belongsTo.default)('terms-and-conditions/terms-and-conditions'), (_class = class TermsAndConditionsModel extends _base.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _initializerDefineProperty(this, "slinky", _descriptor2, this);
      _initializerDefineProperty(this, "parent", _descriptor3, this);
    }
    async open() {
      const device = await this.provider.pull('device', 'getDevice');
      const urlDocument = (0, _object.get)(this, 'urlDocument');
      if (device.isNative) {
        return this.slinky.send('Device.open', {
          intent: urlDocument
        });
      }
      return window.open(urlDocument, '_blank');
    }
    async sign() {
      const capabilities = await this.parent.getCapabilities();
      if (capabilities.upgradeable) {
        return this.provider.push('terms-and-conditions', '_clearDocument', this);
      }
      return this.provider.push('terms-and-conditions', '_signDocument', this);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "parent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = TermsAndConditionsModel;
});