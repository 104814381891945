define("@trixie-html/core-traceability/utils/tealium-context", ["exports", "@ember/object", "@ember/debug", "@trixie-html/core-traceability/utils/deep-set"], function (_exports, _object, _debug, _deepSet) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getContext = getContext;
  _exports.setContext = setContext;
  const LEVELS = ['app', 'session', 'currentRoute', 'multiStep', 'cookiesConsent'];
  const HEADER = '[@trixie-html/core-traceability]';
  function isValidKey(key) {
    return LEVELS.includes(key) || LEVELS.some(level => key.startsWith("".concat(level, ".")));
  }
  function setContext(metrics, key, data) {
    if (!(metrics && key)) {
      (false && (0, _debug.warn)("".concat(HEADER, " cannot `setContext`: ").concat(metrics ? 'key' : 'metrics', " is missing"), null, {
        id: '@trixie-html/core-traceability.setContext.missingParams'
      }));
      return;
    }
    if (!isValidKey(key)) {
      (false && (0, _debug.warn)("".concat(HEADER, " cannot `setContext`: key `").concat(key, "` is not valid"), null, {
        id: '@trixie-html/core-traceability.setContext.invalidKey'
      }));
      return;
    }
    if (!metrics.context.tealiumContext) {
      metrics.context.tealiumContext = {};
    }
    (0, _deepSet.default)(metrics.context.tealiumContext, key, data);
  }
  function getContext(metrics, key) {
    if (!(metrics && key)) {
      (false && (0, _debug.warn)("".concat(HEADER, " cannot `getContext`: ").concat(metrics ? 'key' : 'metrics', " is missing"), null, {
        id: '@trixie-html/core-traceability.getContext.missingParams'
      }));
      return null;
    }
    if (!isValidKey(key)) {
      (false && (0, _debug.warn)("".concat(HEADER, " cannot `getContext`: key `").concat(key, "` is not valid"), null, {
        id: '@trixie-html/core-traceability.getContext.invalidKey'
      }));
      return null;
    }
    return (0, _object.get)(metrics.context.tealiumContext || {}, key);
  }
});