define("@trixie-html/core-session/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.STATUS = _exports.METHODS = _exports.INIT_STATUS = _exports.AUTHCODE_REGEXP = _exports.ACCESS_METHODS = void 0;
  const STATUS = {
    PENDING: 'PENDING_VALIDATION',
    ACTIVE: 'ACTIVE',
    // BLOCKED: 3,
    // DISABLED: 4,
    UNSUBSCRIBED: 'UNSUBSCRIBED',
    OTHER_ACTIVE: 'OTHER_ACTIVE'
  };
  _exports.STATUS = STATUS;
  const AUTHCODE_REGEXP = /^.+(?=token).+(?=software).+: ?(\w+).*$/;
  _exports.AUTHCODE_REGEXP = AUTHCODE_REGEXP;
  const ACCESS_METHODS = {
    BIOMETRICS: 'BIOMETRICS',
    FACE_ID: 'FACE_ID',
    TOUCH_ID: 'TOUCH_ID'
  };
  _exports.ACCESS_METHODS = ACCESS_METHODS;
  const INIT_STATUS = {
    NEED_FINALIZE: 'NEED_FINALIZE',
    COMPLETED: 'COMPLETED'
  };
  _exports.INIT_STATUS = INIT_STATUS;
  const METHODS = {
    [ACCESS_METHODS.BIOMETRICS]: {
      checks: {
        capabilities: {
          support: 'supportsBiometrics',
          activation: 'hasBiometricsRegistered'
        }
      }
    },
    [ACCESS_METHODS.TOUCH_ID]: {
      checks: {
        capabilities: {
          support: 'supportsTouchId',
          activation: 'hasTouchIdRegistered'
        }
      }
    },
    [ACCESS_METHODS.FACE_ID]: {
      checks: {
        capabilities: {
          support: 'supportsFaceId',
          activation: 'hasFaceIdRegistered'
        }
      }
    }
  };
  _exports.METHODS = METHODS;
});