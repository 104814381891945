define("@trixie-html/core-traceability/utils/deep-set", ["exports", "@ember/object", "@ember/utils", "@ember/debug"], function (_exports, _object, _utils, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = deepSet;
  function isObject(obj) {
    return (0, _utils.typeOf)(obj) === 'object' || (0, _utils.typeOf)(obj) === 'instance';
  }
  function deepSet(obj, key, value) {
    (false && !(isObject(obj)) && (0, _debug.assert)('[ember-deep-set] non-object passed in', isObject(obj)));
    (false && !((0, _utils.typeOf)(key) === 'string') && (0, _debug.assert)('[ember-deep-set] `key` must be a string', (0, _utils.typeOf)(key) === 'string'));
    key.split('.').reduce((acc, currentKey, i, allKeys) => {
      const currentValue = (0, _object.get)(acc, currentKey) || {};
      const notObject = allKeys[i + 1] && !isObject(currentValue) ? {} : value;
      const valueToSet = allKeys[i + 1] && isObject(currentValue) ? currentValue : notObject;
      acc[currentKey] = valueToSet;
      return valueToSet;
    }, obj);
    return value;
  }
});