define("@trixie-html/core-traceability/utils/user-gender", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SEX_CODES = _exports.GENDER_CODES = void 0;
  _exports.default = userGender;
  // As returned by contextual data service
  const SEX_CODES = {
    MALE: 'v',
    FEMALE: 'm'
  };

  // As expected by Tealium
  _exports.SEX_CODES = SEX_CODES;
  const GENDER_CODES = {
    MALE: 'masculino',
    FEMALE: 'femenino'
  };

  /*
   * This should build an object like this one:
   *
   * {
   *   v: 'masculino',
   *   m: 'femenino'
   * }
   *
   * to translate from backend to Tealium syntax
   */
  _exports.GENDER_CODES = GENDER_CODES;
  const TRACKING_GENDERS = Object.keys(SEX_CODES).reduce((acc, current) => {
    acc[SEX_CODES[current]] = GENDER_CODES[current];
    return acc;
  }, {});
  function userGender() {
    let gender = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return TRACKING_GENDERS[gender.toLowerCase()] || '';
  }
});