define("ember-route-helpers/helpers/replace-with", ["exports", "ember-route-helpers/helpers/transition"], function (_exports, _transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class TransitionToHelper extends _transition.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "transitionMethod", 'replaceWith');
    }
  }
  _exports.default = TransitionToHelper;
});