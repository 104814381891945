define("ember-collector-dispatcher/storage-adapters/indexed-db", ["exports", "@ember/object", "@ember/utils", "dexie"], function (_exports, _object, _utils, _dexie) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.version = _exports.tableName = _exports.schema = _exports.default = void 0;
  const version = 1;
  _exports.version = version;
  const tableName = 'logs';
  _exports.tableName = tableName;
  const schema = {
    [tableName]: '++_id'
  };
  _exports.schema = schema;
  class IndexedDb extends _object.default {
    init() {
      super.init(...arguments);
      if (!this.database) {
        throw new Error('IndexedDB storage adapter needs a database');
      }
      const db = new _dexie.default(this.database);
      db.version(version).stores(schema);
      this.db = db;
      const table = db.table(tableName);
      this.table = table;
    }
    isSupported() {
      return this.db.open().then(() => true, () => false);
    }
    count() {
      return this.table.count();
    }
    async push() {
      for (var _len = arguments.length, items = new Array(_len), _key = 0; _key < _len; _key++) {
        items[_key] = arguments[_key];
      }
      this.table.bulkPut(items);
    }
    async unshift() {
      const length = await this.count();
      for (var _len2 = arguments.length, items = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        items[_key2] = arguments[_key2];
      }
      if (!length) {
        this.table.bulkPut(items);
        return;
      }
      const firstItem = await this.table.toCollection().first();
      let _id = firstItem._id - 1;
      items.reverse().forEach(item => {
        Object.assign(item, {
          _id
        });
        --_id;
      });
      this.table.bulkPut(items);
    }
    async removeItem(pop) {
      const collection = await this.table.toCollection();
      const currentItem = pop ? await collection.last() : await collection.first();
      if ((0, _utils.isPresent)(currentItem)) {
        await this.table.delete(currentItem._id);
        delete currentItem._id;
        return [currentItem];
      }
      return [];
    }
    async pop() {
      let count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      let result = [];
      await this.db.transaction('rw', this.table, async () => {
        for (let i = 0; i < count; i++) {
          const item = await this.removeItem(true);
          result = [...result, ...item];
        }
      });
      return result;
    }
    async shift() {
      let count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      let result = [];
      await this.db.transaction('rw', this.table, async () => {
        for (let i = 0; i < count; i++) {
          const item = await this.removeItem();
          result = [...result, ...item];
        }
      });
      return result;
    }
  }
  _exports.default = IndexedDb;
});