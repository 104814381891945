define("@trixie-html/addon-discreet-mode/services/discreet-mode", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object", "@ember/application"], function (_exports, _service, _tracking, _object, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DISCREET_MODE = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const DISCREET_MODE = {
    PERMANENT: 'permanent',
    TEMPORAL: 'temporal',
    DESACTIVATED: 'desactivate'
  };
  _exports.DISCREET_MODE = DISCREET_MODE;
  let DiscreetModeService = (_class = class DiscreetModeService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "sessionStorage", _descriptor, this);
      _initializerDefineProperty(this, "applicationStorage", _descriptor2, this);
      _initializerDefineProperty(this, "router", _descriptor3, this);
      _initializerDefineProperty(this, "discreetModeActive", _descriptor4, this);
      _initializerDefineProperty(this, "discreetRouteActive", _descriptor5, this);
    }
    get config() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment')['addon-discreet-mode'];
    }
    async getDiscreetMode() {
      const temporal = await this.sessionStorage.getItem('discreetMode');
      const permanent = await this.applicationStorage.getItem('discreetMode');
      this.discreetMode = temporal || permanent;
      if (temporal) {
        return DISCREET_MODE.TEMPORAL;
      }
      if (permanent) {
        return DISCREET_MODE.PERMANENT;
      }
      return DISCREET_MODE.DESACTIVATED;
    }
    async setDiscreetMode(value) {
      const mode = {
        [DISCREET_MODE.TEMPORAL]: false,
        [DISCREET_MODE.PERMANENT]: false,
        [DISCREET_MODE.DESACTIVATED]: false
      };
      mode[value] = true;
      await this.sessionStorage.setItem('discreetMode', mode.temporal);
      await this.applicationStorage.setItem('discreetMode', mode.permanent);
      const activeMode = await this.getDiscreetMode();
      this.discreetModeActive = Boolean(activeMode !== DISCREET_MODE.DESACTIVATED);
    }
    get discreetRoute() {
      var _this$config, _this$config$routes, _this$router$currentR;
      return (_this$config = this.config) === null || _this$config === void 0 ? void 0 : (_this$config$routes = _this$config.routes) === null || _this$config$routes === void 0 ? void 0 : _this$config$routes.includes(this.getCleanRoute((_this$router$currentR = this.router.currentRoute) === null || _this$router$currentR === void 0 ? void 0 : _this$router$currentR.name));
    }
    getCleanRoute(currentRoute) {
      const splitBySlash = currentRoute.split('/');
      if (splitBySlash[1]) {
        return splitBySlash[1].substr(7);
      }
      return currentRoute;
    }
    async updateDiscreetMode() {
      const activeMode = await this.getDiscreetMode();
      this.discreetModeActive = Boolean(activeMode !== DISCREET_MODE.DESACTIVATED);
      this.discreetRouteActive = Boolean(this.discreetRoute && this.discreetModeActive);
    }
    routeDidChange() {
      this.updateDiscreetMode();
    }
    async attach() {
      const activeMode = await this.getDiscreetMode();
      this.discreetModeActive = Boolean(activeMode !== DISCREET_MODE.DESACTIVATED);
      this.router.on('routeDidChange', this, 'routeDidChange');
    }
    willDestroy() {
      super.willDestroy(...arguments);
      this.router.off('routeDidChange', this, 'routeDidChange');
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sessionStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "applicationStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "discreetModeActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "discreetRouteActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateDiscreetMode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateDiscreetMode"), _class.prototype)), _class);
  _exports.default = DiscreetModeService;
});