define("@trixie-html/core-session/components/modal-pinpositions", ["exports", "@ember/object", "@ember/service", "@trixie-html/core-session/templates/modal-pinpositions", "@trixie-html/core-foundation/components/modal", "@glimmer/tracking", "ember-concurrency-decorators"], function (_exports, _object, _service, _modalPinpositions, _modal, _tracking, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const DEFAULT_IMAGE = 'banners/mobile-sign-in.svg';
  const MAX_ATTEMPTS = 3;
  const ERROR_MESSAGES = ['01237066'];
  let ModalPinpositions = (_dec = (0, _emberConcurrencyDecorators.task)({
    drop: true
  }), (_class = class ModalPinpositions extends _modal.default {
    init() {
      super.init(...arguments);
      const [firstPosition, secondPosition] = this.model.options.resource.headers.authenticationchallenge.split(',').map(Number);
      this.firstPosition = firstPosition;
      this.secondPosition = secondPosition;
    }
    constructor() {
      var _this$veil;
      super(...arguments);

      // TODO: Remove into DB-230740
      _initializerDefineProperty(this, "alerter", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "slinky", _descriptor4, this);
      _initializerDefineProperty(this, "veil", _descriptor5, this);
      _initializerDefineProperty(this, "image", _descriptor6, this);
      _initializerDefineProperty(this, "attempts", _descriptor7, this);
      _initializerDefineProperty(this, "error", _descriptor8, this);
      _initializerDefineProperty(this, "noMoreAttempts", _descriptor9, this);
      _initializerDefineProperty(this, "firstPosition", _descriptor10, this);
      _initializerDefineProperty(this, "secondPosition", _descriptor11, this);
      _initializerDefineProperty(this, "errorDuplicatedPassword", _descriptor12, this);
      _defineProperty(this, "layout", _modalPinpositions.default);
      if ((_this$veil = this.veil) !== null && _this$veil !== void 0 && _this$veil.show) {
        this.veil.remove();
      }
    }
    submitPositions(positions) {
      this.submit.perform(positions);
    }
    *submit(positions) {
      try {
        this.veil.add();
        const options = (0, _object.get)(this, 'model.options');
        const resource = options.resource;
        const pinValue = "".concat(positions[0]).concat(positions[1]);
        const encryptData = {
          value: "".concat(pinValue, "FFFFFFFFFFFFFF"),
          keyTag: 'app_it'
        };
        const {
          value
        } = yield this.slinky.send('Security.encrypt', encryptData);

        // Get promise.
        (0, _object.set)(resource, 'headers.authenticationdata', "pinpositions=".concat(value));
        (0, _object.set)(resource, 'headers.authenticationchallenge', this.model.options.resource.headers.authenticationchallenge);
        const data = yield resource.retry();
        return this.close(data);
      } catch (e) {
        return this.hasErrorMessage(e);
      } finally {
        this.key = '';
        this.veil.remove();
      }
    }
    hasErrorMessage(e) {
      var _e$data;
      if (ERROR_MESSAGES.includes(e === null || e === void 0 ? void 0 : (_e$data = e.data) === null || _e$data === void 0 ? void 0 : _e$data['error-code'])) {
        this.errorDuplicatedPassword = true;
      } else {
        this.error = true;
        this.attempts--;
        if (this.attempts <= 0) {
          this.noMoreAttempts = true;
        }
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "alerter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "image", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return DEFAULT_IMAGE;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "attempts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return MAX_ATTEMPTS;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "noMoreAttempts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "firstPosition", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "secondPosition", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "errorDuplicatedPassword", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "submitPositions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submitPositions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class));
  _exports.default = ModalPinpositions;
});