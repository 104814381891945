define("@trixie-html/addon-tasks/models/tasks", ["exports", "ember-object-data-model/models/base-array", "@ember/object"], function (_exports, _baseArray, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TasksModel extends _baseArray.default {
    get counterTask() {
      return parseInt((0, _object.get)(this, '_data.size') || 0, 10);
    }
  }
  _exports.default = TasksModel;
});