define("@trixie-html/core-traceability/metrics-adapters/tealium", ["exports", "@ember/array", "@ember/object", "@ember/debug", "@ember/application", "@ember/service", "@ember/utils", "ember-copy", "ember-concurrency", "ember-concurrency-decorators", "ember-utils/utils/primitive/merge", "@trixie-html/core-traceability/utils/get-adapter-config", "@trixie-html/core-traceability/utils/get-base-object", "@trixie-html/core-traceability/utils/is-adapter-enabled", "@trixie-html/core-traceability/utils/is-adapter-in-dryRun", "@trixie-html/core-traceability/utils/load-script"], function (_exports, _array, _object, _debug, _application, _service, _utils, _emberCopy, _emberConcurrency, _emberConcurrencyDecorators, _merge, _getAdapterConfig2, _getBaseObject, _isAdapterEnabled, _isAdapterInDryRun, _loadScript) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.removeFromDOM = removeFromDOM;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const RETRY_DIGEST_MAX = 10;
  const RETRY_DIGEST_TIMEOUT = 50;
  const LOGGING_HEADER = '[@trixie-html/core-traceability]';
  function getEventContext(event) {
    const {
      app: _app,
      session: _session,
      currentRoute: _currentRoute,
      multiStep: _multiStep,
      cookiesConsent: _cookiesConsent
    } = event.tealiumContext;
    return {
      app: _app ? (0, _emberCopy.copy)(_app, true) : null,
      session: _session ? (0, _emberCopy.copy)(_session, true) : null,
      currentRoute: _currentRoute ? (0, _emberCopy.copy)(_currentRoute, true) : null,
      multiStep: _multiStep ? (0, _emberCopy.copy)(_multiStep, true) : null,
      cookiesConsent: _cookiesConsent ? (0, _emberCopy.copy)(_cookiesConsent, true) : null
    };
  }
  function removeFromDOM(selectors) {
    document.querySelectorAll(selectors).forEach(el => {
      var _el$parentElement;
      (_el$parentElement = el.parentElement) === null || _el$parentElement === void 0 ? void 0 : _el$parentElement.removeChild(el);
    });
  }
  let TealiumAdapter = (_dec = (0, _emberConcurrencyDecorators.task)({
    restartable: true
  }), (_class = class TealiumAdapter extends _object.default {
    get maxRetriesExhausted() {
      return this._retryCount >= RETRY_DIGEST_MAX;
    }
    constructor(configuration, owner) {
      var _getAdapterConfig;
      super(...arguments);
      _initializerDefineProperty(this, "scheduler", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
      _defineProperty(this, "_retryCount", 0);
      _defineProperty(this, "eventQueue", (0, _array.A)());
      _defineProperty(this, "appendQueue", (0, _array.A)());
      _defineProperty(this, "laterTimer", void 0);
      _defineProperty(this, "metrics", null);
      _defineProperty(this, "config", null);
      (0, _application.setOwner)(this, owner);
      this.config = configuration;
      const config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      const adapterConfig = (_getAdapterConfig = (0, _getAdapterConfig2.default)(config, 'tealium')) === null || _getAdapterConfig === void 0 ? void 0 : _getAdapterConfig.config;
      const addonConfig = config['core-traceability'];
      this._scriptUrl = addonConfig.scriptUrl;
      this._asyncScriptUrl = addonConfig.asyncScriptUrl;
      this._normalizerName = addonConfig.tealiumVersion || 'canonical';
      this._versionNormalizer = (0, _application.getOwner)(this).lookup("normalizer:".concat(this._normalizerName));
      this.dryRun = !(0, _isAdapterEnabled.default)(config) || (0, _isAdapterInDryRun.default)(config);
      this.enableDebug = adapterConfig === null || adapterConfig === void 0 ? void 0 : adapterConfig.debug;
      if (!this.dryRun) {
        (0, _loadScript.default)(this._scriptUrl);
        (0, _loadScript.default)(this._asyncScriptUrl, 'head');
      }
      window.digitalData = {};
    }
    trackEvent(event) {
      this.scheduler.schedule(this, 'pushEvent', event);
    }
    pushEvent(event) {
      this.debug('Event added to queue', event);
      this.eventQueue.addObject(event);
      this.updateEventQueue();
      this.digestTask.perform();
    }
    *digestTask() {
      this.debug("Digest event queue (".concat(this.eventQueue.length, " elements)"));
      this._retryCount = 0;
      while (!this.maxRetriesExhausted && !this._digest()) {
        this._retryCount++;
        yield (0, _emberConcurrency.timeout)(RETRY_DIGEST_TIMEOUT);
      }
    }
    _digest() {
      var _this$eventQueue;
      if (!((_this$eventQueue = this.eventQueue) !== null && _this$eventQueue !== void 0 && _this$eventQueue.length)) {
        return true;
      }
      if (this.dryRun || typeof window.digitalChange === 'function') {
        this.eventQueue.forEach(this.scheduler.schedule.bind(this.scheduler, this, 'sendEvent'));
        this.eventQueue.clear();
        return true;
      }
      (false && (0, _debug.warn)("".concat(LOGGING_HEADER, " unable to digest! retry in ").concat(RETRY_DIGEST_TIMEOUT, "ms"), {
        id: 'trixie.core-traceability.missing-digital-change-fn'
      }));
      return false;
    }
    sendEvent(event) {
      // istanbul ignore else: No necessary test when tealium is isDestroying or isDestroyed
      if (!(this.isDestroying || this.isDestroyed)) {
        let payload = event.payload;
        if (event.tealiumContext) {
          const {
            app,
            session,
            currentRoute,
            multiStep,
            cookiesConsent
          } = getEventContext(event);
          payload = (0, _merge.default)({}, app, session, currentRoute, multiStep, cookiesConsent, event.payload);
        }
        this.send(event.type, payload);
        return;
      }
    }
    append(options) {
      const context = Object.keys(options)[0];
      delete options[context];
      this.appendQueue.pushObject(options);
    }
    updateEventQueue() {
      var _this$eventQueue2, _this$appendQueue;
      if ((_this$eventQueue2 = this.eventQueue) !== null && _this$eventQueue2 !== void 0 && _this$eventQueue2.length && (_this$appendQueue = this.appendQueue) !== null && _this$appendQueue !== void 0 && _this$appendQueue.length) {
        const lastEvent = this.eventQueue.lastObject.payload;
        this.appendQueue.forEach(appendBlock => {
          (0, _merge.default)(lastEvent, appendBlock);
        });
        this.appendQueue = (0, _array.A)();
      }
    }
    send(type, payload) {
      for (const key in payload) {
        if (Object.hasOwnProperty.call(payload, key)) {
          const value = payload[key];
          if (!(0, _utils.isEmpty)(value)) {
            window.digitalData[key] = value;
          }
        }
      }

      // Complete empty fields
      window.digitalData = (0, _merge.default)((0, _getBaseObject.default)(), window.digitalData);
      this.debug('`canonical object` params:', type, window.digitalData);
      if (!this.dryRun) {
        const normalizedData = this._versionNormalizer.normalize(window.digitalData);
        this.debug("`sending ".concat(this._normalizerName, " object` params:"), type, normalizedData);
        window.digitalChange(type, normalizedData);
      }
      window.digitalData = {};
    }
    willDestroy() {
      this.eventQueue.clear();
      this.digestTask.cancelAll();
      removeFromDOM("script[src=\"".concat(this._scriptUrl, "\"]"));
      delete window.digitalData;
    }
    debug(message) {
      if (this.enableDebug) {
        for (var _len = arguments.length, groupValues = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          groupValues[_key - 1] = arguments[_key];
        }
        this.debugIntoConsole(message, ...groupValues);
      }
    }
    debugIntoConsole(message) {
      for (var _len2 = arguments.length, groupValues = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        groupValues[_key2 - 1] = arguments[_key2];
      }
      (0, _debug.runInDebug)(() => {
        if (groupValues !== null && groupValues !== void 0 && groupValues.length) {
          console.groupCollapsed("".concat(LOGGING_HEADER, " ").concat(message));
          groupValues.forEach(value => {
            console.debug(value);
          });
          console.groupEnd();
        } else {
          console.debug("".concat(LOGGING_HEADER, " ").concat(message));
        }
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "scheduler", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "digestTask", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "digestTask"), _class.prototype)), _class));
  _exports.default = TealiumAdapter;
});