define("@trixie-html/core-semaas/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SECONDS_TO_MILLISECONDS = _exports.MILLISECONDS_TO_NANO = void 0;
  const MILLISECONDS_TO_NANO = 1e6;
  _exports.MILLISECONDS_TO_NANO = MILLISECONDS_TO_NANO;
  const SECONDS_TO_MILLISECONDS = 1e3;
  _exports.SECONDS_TO_MILLISECONDS = SECONDS_TO_MILLISECONDS;
});