define("ember-google-maps/utils/async-data", ["exports", "@glimmer/tracking", "ember-google-maps/utils/platform", "@ember/object/proxy", "@ember/object/promise-proxy-mixin"], function (_exports, _tracking, _platform, _proxy, _promiseProxyMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getAsync = getAsync;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PromiseProxy = (_class = class PromiseProxy {
    constructor(promise) {
      _initializerDefineProperty(this, "isRejected", _descriptor, this);
      _initializerDefineProperty(this, "isFulfilled", _descriptor2, this);
      _initializerDefineProperty(this, "content", _descriptor3, this);
      this.promise = promise.then(result => {
        this.content = result;
        this.isFulfilled = true;
        return result;
      }).catch(error => {
        this.isRejected = true;
        throw error;
      });
      let get = (_target, prop) => {
        switch (prop) {
          case 'promise':
            return this.promise;
          case 'then':
          case 'catch':
          case 'finally':
            return this.promise[prop].bind(this.promise);
          default:
            if (this.isFulfilled && this.content) {
              return Reflect.get(this.content, prop);
            }
        }
      };
      return new Proxy(this, {
        get
      });
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "content", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class);
  function getAsync(prototype, key, desc) {
    let PROMISES = new WeakMap();
    function getter() {
      let existingProxy = PROMISES.get(desc);
      if (existingProxy) {
        return existingProxy;
      }
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      let promise = desc.get.call(this, ...args);
      let proxy;
      if (_platform.HAS_NATIVE_PROXY) {
        proxy = new PromiseProxy(promise);
      } else {
        proxy = getAsyncNoProxyFallback(promise);
      }
      PROMISES.set(desc, proxy);
      return proxy;
    }
    return {
      get: getter
    };
  }
  const ObjectPromiseProxy = _proxy.default.extend(_promiseProxyMixin.default);
  function getAsyncNoProxyFallback(promise) {
    return ObjectPromiseProxy.create({
      promise
    });
  }
});