define("@trixie-html/core-semaas/services/semaas-otp-dispatcher", ["exports", "ember-collector-dispatcher/services/dispatcher", "@ember/service", "@woody-mrs-potato/core-http/utils/fetchify", "@ember/application", "@woody-mrs-potato/core-http/errors/fetch", "@ember/object"], function (_exports, _dispatcher, _service, _fetchify, _application, _fetch, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let SemaasOtpDispatcherService = (_dec = (0, _service.inject)('semaas-otp-collector'), (_class = class SemaasOtpDispatcherService extends _dispatcher.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "provider", _descriptor, this);
      _initializerDefineProperty(this, "collector", _descriptor2, this);
      _defineProperty(this, "currentSession", null);
      _defineProperty(this, "maxTimeout", this.config.timeout);
      _defineProperty(this, "maxConcurrent", this.config.chunkSize);
      _defineProperty(this, "metricSet", 'otp_metric_s');
    }
    get config() {
      const {
        semaas
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return semaas;
    }
    setCurrentSession(currentSession) {
      this.currentSession = currentSession;
    }
    async getSemaasSession() {
      var _this$currentSession;
      if (!((_this$currentSession = this.currentSession) !== null && _this$currentSession !== void 0 && _this$currentSession.isSynchronized)) {
        return null;
      }
      try {
        return await this.provider.pull('semaas', 'getSession', this.currentSession);
      } catch (e) {
        return null;
      }
    }
    async dispatch(items) {
      if (this.isDestroyed) {
        await this.stop();
        return items;
      }
      const session = await this.getSemaasSession();
      if (!session || !(0, _object.get)(session, 'isValid')) {
        await this.stop();
        return items;
      }
      try {
        await this.sendMeasurements(session, items);
        return [];
      } catch (e) {
        if (!(e instanceof _fetch.default)) {
          await this.stop();
        }
        return [];
      }
    }
    sendMeasurements(session, measurements) {
      const options = {
        url: "".concat(this.config.hosts.mu, "/v0/ns/").concat(this.config.reliabilityNamespace, "/metric-sets/").concat(this.metricSet, ":addMeasurements"),
        method: 'POST',
        headers: {
          authorization: "Bearer ".concat((0, _object.get)(session, 'accessToken')),
          'content-type': 'application/json; charset=utf-8'
        },
        data: JSON.stringify({
          metrics: measurements
        })
      };
      return new _fetchify.default(options).fetch();
    }
    createMeasurement(body) {
      const factory = (0, _application.getOwner)(this).factoryFor('model:measurement');
      const measurement = factory.create(body);
      const obj = measurement.toJSON();
      this.collector.push(obj);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "collector", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = SemaasOtpDispatcherService;
});