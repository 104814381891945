define("@trixie-html/core-session/templates/modal-initial-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vXDMud9z",
    "block": "[[[8,[39,0],[[24,0,\"panel-container\"],[24,\"data-theme\",\"dark300\"]],null,[[\"default\"],[[[[1,\"\\n\\t\"],[8,[30,1,[\"content\"]],null,[[\"@extraContentClasses\",\"@extraMainClasses\"],[\"bg_transparent\",\"flexy-item justify-center align-centered\"]],[[\"default\"],[[[[1,\"\\n\\t\\t\"],[8,[39,1],null,[[\"@image\",\"@level\",\"@title\"],[[28,[37,2],[\"banners/alert.svg\"],null],1,[30,0,[\"model\",\"options\",\"title\"]]]],[[\"default\"],[[[[1,\"\\n\\t\\t\\t\"],[8,[30,2,[\"text\"]],null,null,[[\"default\"],[[[[1,\"\\n\\t\\t\\t\\t\"],[1,[30,0,[\"model\",\"options\",\"message\"]]],[1,\"\\n\\t\\t\\t\"]],[]]]]],[1,\"\\n\\t\\t\"]],[2]]]]],[1,\"\\n\\t\"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"wrapper\",\"panel\"],false,[\"wrapper-main\",\"panels/panel-info\",\"image-path\"]]",
    "moduleName": "@trixie-html/core-session/templates/modal-initial-message.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});