define("@trixie-html/engine-user-profile/routes", ["exports", "ember-engines/routes"], function (_exports, _routes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _routes.default)(function () {
    this.route('change-mail', function () {
      this.route('confirmation');
    });
    this.route('validate-mail', function () {
      this.route('confirmation');
    });
    this.route('change-password', function () {
      this.route('confirmation');
    });
    this.route('customer-phones');
  });
  _exports.default = _default;
});