define("@trixie-html/addon-terms-and-conditions/gams/tyc-texts", ["exports", "@trixie-html/core-network/resources/gam", "@ember/object", "ember-utils/utils/banking/identification-unformatter"], function (_exports, _gam, _object, _identificationUnformatter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class TycTextsGam extends _gam.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endpoint", '/mobileprivateconfig1.0');
    }
    serialize() {
      const document = (0, _identificationUnformatter.default)((0, _object.get)(this.session, 'username'));
      return {
        document,
        isEmployee: this.customer.isEmployee,
        isPreactivated: this.session.isPreactivated,
        isPreoperative: this.session.isPreoperative,
        isPhoneValidated: this.customer.isPhoneValidated,
        previousAppVersion: this.previousAppVersion,
        segments: this.customer.segments
      };
    }
  }
  _exports.default = TycTextsGam;
});