define("ember-route-helpers/helpers/replace-with-external", ["exports", "@ember/application", "ember-route-helpers/helpers/transition"], function (_exports, _application, _transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  /**
   * Given this "external routes":
   *
   * ```js
   * // host app
   * dependencies: {
   *   externalRoutes: {
   *     posts: 'blog.posts'
   *   }
   * }
   * ```
   *
   * ```js
   * // engine
   * dependencies: {
   *   externalRoutes: [
   *     'posts'
   *   ]
   * }
   * ```
   *
   * You can then use those external routes within a template:
   *
   * ```hbs
   * {{my-component onclick=(replace-with-external 'posts' (query-params id=3 comments=true)}}
   * ```
   */
  class TransitionToHelper extends _transition.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "transitionMethod", 'replaceWith');
    }
    getRouteName(routeName) {
      const owner = (0, _application.getOwner)(this);
      return owner._getExternalRoute(routeName);
    }
  }
  _exports.default = TransitionToHelper;
});