define("ember-task-scheduler/services/scheduler", ["exports", "ember", "@ember/service", "@ember/runloop", "@ember/debug", "@ember/application", "@ember/test-waiters"], function (_exports, _ember, _service, _runloop, _debug, _application, _testWaiters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  const FPS = 60;
  const MILLISECONDS = 1000;
  const {
    onerror
  } = _ember.default;
  const {
    requestAnimationFrame,
    cancelAnimationFrame,
    performance
  } = window;
  const waiter = (0, _testWaiters.buildWaiter)('ember-task-scheduler');

  /**
   * Bind context to method and call requestAnimationFrame with generated function.
   *
   * @method scheduleFrame
   * @param {Object} context
   * @param {String} method
   * @return Number
   * @private
   */
  function scheduleFrame(context, method) {
    method = context[method];
    return requestAnimationFrame(method.bind(context));
  }

  /**
   * Try to exec a function with target and args.
   *
   * When function throws an error it calls onError function with error and stack.
   *
   * @method exec
   * @param {Object} target
   * @param {Function|String} method
   * @param {Array} args
   * @param {Function} onError
   * @param {Error} stack
   * @private
   */
  function exec(target, method, args, onError, stack) {
    try {
      method.apply(target, args);
    } catch (e) {
      if (onError) {
        onError(e, stack);
      }
    }
  }

  /**
   * Logs a grouped stack trace.
   *
   * Fallback to warn() from @ember/debug
   *
   * @param  {String} title   Group title
   * @param  {Object} stack   Stack trace
   * @param  {Boolean} test   An optional boolean. If falsy, the warning will be displayed.
   * @param  {Object} options Can be used to pass a unique `id` for this warning.
   */
  /* istanbul ignore next */
  function _logWarn(title, stack) {
    let test = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    if (test) {
      return;
    }
    if (console.groupCollapsed && console.trace && console.groupEnd) {
      console.groupCollapsed(title);
      console.log(options.id);
      console.trace(stack.stack);
      console.groupEnd(title);
    } else {
      (false && (0, _debug.warn)("".concat(title, "\n").concat(stack.stack), test, options));
    }
  }

  /**
   * Service that schedules tasks into browser frames within a given FPS rate.
   *
   * When there are several tasks that fits into the same frame, it tries to execute them.
   * Otherwise, when there are heavy tasks, it tries to spare them into several frames.
   *
   * Methods:
   *  * schedule: add a task into the scheduler.
   *  * scheduleOnce: add a unique task into the scheduler.
   *  * cancel [Array]: array with deleted tasks. Format of each item: [target, method, args].
   *  * hasPendingTasks [Boolean]: return true when there are pending tasks.
   *
   * @namespace App
   * @class SchedulerService
   * @extends Service
   * @public
   */
  class SchedulerService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "onError", onerror);
      _defineProperty(this, "_tasks", []);
      _defineProperty(this, "_currentInstance", null);
      _defineProperty(this, "_waiterToken", null);
    }
    /**
     * Proxy to app environment configuration.
     *
     * @property config
     * @type Object
     */
    get config() {
      return (0, _application.getOwner)(this).resolveRegistration('config:environment');
    }

    /**
     * Setup number of frames per second.
     *
     * @property FPS
     * @type Number
     * @public
     */
    get FPS() {
      var _this$config$taskSche;
      return this._FPS || ((_this$config$taskSche = this.config.taskScheduler) === null || _this$config$taskSche === void 0 ? void 0 : _this$config$taskSche.FPS);
    }
    set FPS(value) {
      this._FPS = value;
    }

    /**
     * On error hook executed when a task fails.
     *
     * @method onError
     * @param {Error} e
     * @public
     */

    /**
     * Computed value of milliseconds per frame of current FPS configuration.
     *
     * @property millisecondsPerFrame
     * @type Float
     * @public
     */
    get millisecondsPerFrame() {
      const fps = this.FPS || FPS;
      return 1 / fps * MILLISECONDS;
    }

    /**
     * Return when has pending tasks.
     *
     * @method hasPendingTasks
     * @returns Boolean
     * @public
     */
    hasPendingTasks() {
      return this._tasks.length !== 0;
    }

    /**
     * Schedules a task into the scheduler.
     *
     * When first argument is a function it ignores the rest.
     *
     * @method schedule
     * @param {Object} target
     * @param {Function|String} method
     * @param {...Mixed} args
     * @public
     */
    schedule() {
      const tasks = this._tasks;
      const currentInstance = this._currentInstance;
      tasks.push(this._sliceArguments(...arguments));
      if (!currentInstance) {
        this._begin();
      }
    }

    /**
     * Schedule a unique task into the scheduler.
     *
     * When first argument is a function it ignores the rest.
     *
     * @method scheduleOnce
     * @param {Object} target
     * @param {Function|String} method
     * @param {...Mixed} args
     * @public
     */
    scheduleOnce() {
      const currentInstance = this._currentInstance;
      this._pushUnique(this._sliceArguments(...arguments));
      if (!currentInstance) {
        this._begin();
      }
    }

    /**
     * Try to cancel a given task.
     *
     * When first argument is a function it ignores the rest.
     *
     * @method cancel
     * @param {Object} target
     * @param {Function|String} method
     * @returns Array
     * @public
     */
    cancel() {
      const currentInstance = this._currentInstance;
      const [target, method] = this._sliceArguments(...arguments);
      const tasks = this._tasks;
      const removedTasks = [];
      const removedIndexes = [];

      // Find removable tasks.
      for (let i = 0; i < tasks.length; i++) {
        const [currentTarget, currentMethod] = tasks[i];
        if (currentTarget === target && currentMethod === method) {
          removedIndexes.push(i);
        }
      }

      // Remove tasks.
      for (let i = 0; i < removedIndexes.length; i++) {
        const index = removedIndexes[i];
        removedTasks.push(tasks.splice(index, 1));
      }
      if (currentInstance && tasks.length === 0) {
        this._end();
      }
      return removedTasks;
    }

    /**
     * Push unique task into scheduler.
     *
     * When a duplicate is found, replace old arguments with new one.
     *
     * @method _pushUnique
     * @param {Array} params
     * @private
     */
    _pushUnique(params) {
      const [target, method, args, stack] = params;
      const tasks = this._tasks;
      for (let i = 0; i < tasks.length; i++) {
        const [currentTarget, currentMethod] = tasks[i];
        if (currentTarget === target && currentMethod === method) {
          tasks[i][2] = args; // replace args
          tasks[i][3] = stack; // eslint-disable-line no-magic-numbers

          return;
        }
      }
      tasks.push(params);
    }

    /**
     * Begin a new frame scheduling loop.
     *
     * @method _begin
     * @private
     */
    _begin() {
      (false && !(!this._currentInstance) && (0, _debug.assert)('Could not schedule a new frame. Scheduler instance is already started', !this._currentInstance));
      this._waiterToken = waiter.beginAsync();
      this._currentInstance = scheduleFrame(this, '_loop');
    }

    /**
     * Schedule a new frame loop.
     *
     * @method _next
     * @private
     */
    _next() {
      (false && !(this._currentInstance) && (0, _debug.assert)('Could not schedule next frame. Scheduler instance is not running', this._currentInstance));
      (false && !(this.hasPendingTasks()) && (0, _debug.assert)('Could not schedule next frame. Scheduler has no tasks', this.hasPendingTasks()));
      this._currentInstance = scheduleFrame(this, '_loop');
    }

    /**
     * End current frame loop.
     *
     * If frame loop is not started, it will be canceled.
     *
     * @method _end
     * @private
     */
    _end() {
      const currentInstance = this._currentInstance;
      (false && !(currentInstance) && (0, _debug.assert)('Could not stop scheduler. Service instance is not running', currentInstance));
      cancelAnimationFrame(currentInstance);
      this._currentInstance = null;
      waiter.endAsync(this._waiterToken);
    }

    /**
     * Ember hook.
     *
     *
     * @method willDestroy
     * @private
     */
    // istanbul ignore next
    willDestroy() {
      super.willDestroy(...arguments);
      if (this._currentInstance) {
        this._end();
      }
    }

    /**
     * Frame running loop. It tries to fit tasks in a given frame until frame takes too long.
     *
     * @method _loop
     * @param {Float} startTime
     * @private
     */
    _loop(startTime) {
      // istanbul ignore if: lifecycle
      if (this.isDestroyed) {
        return;
      }
      const millisecondsPerFrame = this.millisecondsPerFrame;
      const tasks = this._tasks;
      let target, method, args, stack;
      (false && !(tasks.length !== 0) && (0, _debug.assert)('Could not run current loop. Service instance has no tasks.', tasks.length !== 0));
      do {
        [target, method, args, stack] = tasks.shift();
        this._exec(target, method, args, stack);
      } while (!this.isDestroyed && tasks.length > 0 && performance.now() - startTime < millisecondsPerFrame);

      // After exec, service could be destroyed. Recheck.
      // istanbul ignore if: lifecycle
      if (this.isDestroyed) {
        return;
      }
      if (tasks.length > 0) {
        this._next();
        return;
      }
      const currentInstance = this._currentInstance;
      if (currentInstance) {
        this._end();
      }
    }

    /**
     * Execute function inside ember run loop.
     *
     * @method _exec
     * @param {Object} target
     * @param {Function|String} method
     * @param {Mixed} args
     * @param {Error} stack
     * @private
     */
    _exec(target, method, args, stack) {
      const env = this.config.environment;
      const millisecondsPerFrame = this.millisecondsPerFrame;
      const onError = this.onError;
      let startTime;

      /* istanbul ignore next */
      if (env === 'development') {
        startTime = performance.now();
      }
      (0, _runloop.run)(() => exec(target, method, args, onError, stack));

      /* istanbul ignore next */
      if (env === 'development') {
        const diff = performance.now() - startTime;
        _logWarn("Scheduled callback took too long (".concat(diff, " ms)"), stack, diff < millisecondsPerFrame, {
          id: 'ember-task-scheduler.services.callback-took-too-long'
        });
      }
    }

    /**
     * Parse arguments and try to extract target, method and args.
     *
     * When first argument is a function ignore the rest and set target to null.
     *
     * @method _sliceArguments
     * @param {Object} target
     * @param {Function|String} method
     * @param {...Mixed} args
     * @private
     */
    _sliceArguments(target, method) {
      for (var _len = arguments.length, args = new Array(_len > 2 ? _len - 2 : 0), _key = 2; _key < _len; _key++) {
        args[_key - 2] = arguments[_key];
      }
      const env = this.config.environment;
      const length = arguments.length;
      if (length === 1) {
        method = target;
        target = null;
      }
      if (typeof method === 'string') {
        method = target[method];
      }
      const stack = env === 'development' ? /* istanbul ignore next */new Error() : null;
      (false && !(method && typeof method === 'function') && (0, _debug.assert)('Could not find a valid method to call', method && typeof method === 'function'));
      return [target, method, args, stack];
    }
  }
  _exports.default = SchedulerService;
});