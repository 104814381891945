define("ember-alerter/models/alert", ["exports", "@ember/object", "@ember/object/computed"], function (_exports, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Object that extends functionality of alert.
   *
   * @extends Ember.Object
   */
  var _default = _object.default.extend({
    /**
     * Toggles the visibility of the alert.
     *
     * @property isShown
     * @type Boolean
     */
    isShown: false,
    /**
     * Default duration (milliseconds).
     *
     * @property duration
     * @type Number
     */
    duration: 4000,
    /**
     * Check duration to set as permanent.
     *
     * @property isPermanent
     * @type Boolean
     */
    isPermanent: (0, _computed.lt)('duration', 0)
  });
  _exports.default = _default;
});