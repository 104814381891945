define("@trixie-html/addon-terms-and-conditions/enaxs/validate-non-financial-service", ["exports", "@trixie-html/core-session/resources/client-enax"], function (_exports, _clientEnax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class NonFinancialServicesEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endpoint", '/non-financial-services/v0/non-financial-services/validate-product');
      _defineProperty(this, "smc", 'SMC201800412');
    }
    serialize(data) {
      return {
        targetUserId: this.currentSession.id,
        nonFinancialProduct: {
          id: this.evidenceId
        },
        ...data
      };
    }
  }
  _exports.default = NonFinancialServicesEnax;
});