define("liquid-fire/templates/components/get-outlet-state", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BWy6k+CT",
    "block": "[[[18,1,[[53,\"outletState\"]]]],[\"&default\"],false,[\"yield\",\"-get-dynamic-var\"]]",
    "moduleName": "liquid-fire/templates/components/get-outlet-state.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});