define("@trixie-html/core-session/models/devices-enrolled", ["exports", "ember-object-data-model/models/base", "@trixie-html/addon-utils/helpers/capitalize-first", "@ember/object"], function (_exports, _base, _capitalizeFirst, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DevicesEnrolledModel extends _base.default {
    get infoDevice() {
      const manufacturer = (0, _capitalizeFirst.capitalizeFirst)((0, _object.get)(this, 'manufacturer'));
      const deviceModel = (0, _object.get)(this, 'model').replace(/\+/g, ' ');
      return "".concat(manufacturer, " ").concat(deviceModel);
    }
  }
  _exports.default = DevicesEnrolledModel;
});