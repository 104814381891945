define("@trixie-html/core-traceability/normalizers/v1-13", ["exports", "@ember/object", "ember-copy"], function (_exports, _object, _emberCopy) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const APPLICATION_PRODUCTS_PROPERTIES = ['amount', 'paymentAmount', 'numberOfPayments', 'paymentDate', 'paymentType', 'serviceCharge', 'currency', 'term', 'interestRate', 'numberOfHolders'];
  const PRODUCT_PROPERTIES = ['primaryCategory', 'productSubtype', 'productName', 'productCode', 'quantity'];
  class V113Normalizer extends _object.default {
    _pageInfo(data) {
      data.bussinessUnit = data.businessUnit;
      delete data.businessUnit;
      return data;
    }
    _pageActivity(data) {
      data.nameOfVideoDisplayed = data.video.attributes[0].nameOfVideoDisplayed;
      delete data.audio;
      delete data.video;
      delete data.loginType;
      return data;
    }
    _applicationProduct(application, products) {
      const values = (0, _object.getProperties)(products.attributes[0], APPLICATION_PRODUCTS_PROPERTIES);
      return {
        ...application,
        ...values
      };
    }
    _product(products) {
      const values = (0, _object.getProperties)(products.attributes[0], PRODUCT_PROPERTIES);
      return [{
        ...values
      }];
    }
    _user(data) {
      data.segment = data.segment[0];
      return data;
    }
    normalize(eventData) {
      var _data$application$ste;
      const data = (0, _emberCopy.copy)(eventData, true);
      data.versionDL = '20180424_1.13';
      data.page.pageInfo = this._pageInfo(data.page.pageInfo);
      data.page.pageActivity = this._pageActivity(data.page.pageActivity);
      data.application = this._applicationProduct(data.application, data.products);
      data.application.step = (_data$application$ste = data.application.step) === null || _data$application$ste === void 0 ? void 0 : _data$application$ste.toString();
      data.product = this._product(data.products);
      data.user = this._user(data.user);
      delete data.products;
      delete data.optimization;
      delete data.news;
      return data;
    }
  }
  _exports.default = V113Normalizer;
});