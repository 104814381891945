define("ember-modal-service/templates/components/modal-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Rg395K5B",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"modal\",\"content\"]]],null]],null],null,[[[1,\"\\t\"],[46,[30,1,[\"fullname\"]],null,[[\"model\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null]],[\"model\"],false,[\"each\",\"-track-array\",\"component\"]]",
    "moduleName": "ember-modal-service/templates/components/modal-container.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});