define("ember-modal-service/helpers/open-modal", ["exports", "@ember/debug", "@ember/component/helper", "@ember/utils", "@ember/service"], function (_exports, _debug, _helper, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let OpenModalHelper = (_class = class OpenModalHelper extends _helper.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "modal", _descriptor, this);
    }
    compute(_ref, actions) {
      let [modalName, options] = _ref;
      (false && !((0, _utils.isNone)(actions.onDone) || Boolean(actions.onDone) && (0, _utils.typeOf)(actions.onDone) === 'function') && (0, _debug.assert)('The action `onDone` for the modal service must be a function', (0, _utils.isNone)(actions.onDone) || Boolean(actions.onDone) && (0, _utils.typeOf)(actions.onDone) === 'function'));
      (false && !((0, _utils.isNone)(actions.onFail) || Boolean(actions.onFail) && (0, _utils.typeOf)(actions.onFail) === 'function') && (0, _debug.assert)('The action `onFail` for the modal service must be a function', (0, _utils.isNone)(actions.onFail) || Boolean(actions.onFail) && (0, _utils.typeOf)(actions.onFail) === 'function'));
      return () => {
        this.modal.open(modalName, options).then(actions.onDone, actions.onFail, 'open-modal helper');
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = OpenModalHelper;
});