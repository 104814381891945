define("@trixie-html/core-semaas/models/span", ["exports", "@ember/object", "ember-utils/utils/hash/guid", "@ember/debug", "@ember/utils", "@ember/application", "@trixie-html/core-semaas/constants", "@trixie-html/core-semaas/decorators/evented"], function (_exports, _object, _guid, _debug, _utils, _application, _constants, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function getPresentKeys(object) {
    const copy = {};
    for (const [key, value] of Object.entries(object)) {
      if ((0, _utils.isPresent)(value)) {
        copy[key] = value;
      }
    }
    return copy;
  }
  let Span = (_dec = (0, _object.computed)('parent.traceId'), _dec2 = (0, _object.computed)('parent.{mrId,spanId}', 'config.namespace'), _dec3 = (0, _object.computed)('_startTime', 'offset'), _dec4 = (0, _object.computed)('_stopTime', 'offset'), (0, _evented.default)(_class = (_class2 = class Span extends _object.default {
    get config() {
      const {
        semaas
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      return semaas;
    }
    get traceId() {
      return (0, _object.get)(this, 'parent.traceId') || (0, _guid.default)();
    }
    get parentSpan() {
      const {
        parent
      } = this;
      if (!parent) {
        return null;
      }
      return "ns/".concat(this.config.namespace, "/mrs/").concat(parent.mrId, "/spans/").concat(parent.spanId);
    }
    constructor() {
      super(...arguments);
      _defineProperty(this, "spanId", (0, _guid.default)());
      _defineProperty(this, "parent", null);
      _defineProperty(this, "name", null);
      _defineProperty(this, "mrId", null);
      _defineProperty(this, "offset", 0);
      _defineProperty(this, "properties", {});
      _defineProperty(this, "_startTime", null);
      _defineProperty(this, "_endTime", null);
      this.serializeProperties = ['mrId', 'name', 'parentSpan', 'properties', 'spanId', 'traceId', 'startDate', 'finishDate'];
    }
    start() {
      (false && !((0, _utils.isEmpty)(this._startTime)) && (0, _debug.assert)('Span is already started', (0, _utils.isEmpty)(this._startTime)));
      (0, _object.set)(this, '_startTime', performance.now());
      this.trigger('start');
    }
    stop() {
      (false && !((0, _utils.isPresent)(this._startTime)) && (0, _debug.assert)('Span is not started yet', (0, _utils.isPresent)(this._startTime)));
      (false && !((0, _utils.isEmpty)(this._stopTime)) && (0, _debug.assert)('Span is already stopped', (0, _utils.isEmpty)(this._stopTime)));
      (0, _object.set)(this, '_stopTime', performance.now());
      this.trigger('stop');
    }
    calculateTimeOffset(delta) {
      const start = performance.timing.navigationStart || 0;
      const time = (start + delta) * _constants.MILLISECONDS_TO_NANO;
      return time + this.offset;
    }
    get startDate() {
      const startTime = this._startTime;
      if ((0, _utils.isNone)(startTime)) {
        return null;
      }
      return this.calculateTimeOffset(startTime);
    }
    get finishDate() {
      const stopTime = this._stopTime;
      if ((0, _utils.isNone)(stopTime)) {
        return null;
      }
      return this.calculateTimeOffset(stopTime);
    }
    addProperties(content) {
      (0, _object.setProperties)(this.properties, content);
    }
    toJSON() {
      const {
        serializeProperties
      } = this;
      // eslint-disable-next-line ember/classic-decorator-no-classic-methods
      let object = (0, _object.getProperties)(this, serializeProperties);
      object = getPresentKeys(object);
      for (const [key, value] of Object.entries(object)) {
        if ((0, _utils.typeOf)(value) === 'object') {
          object[key] = getPresentKeys(value);
        }
      }
      return object;
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "traceId", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "traceId"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "parentSpan", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "parentSpan"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "startDate", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "startDate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "finishDate", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "finishDate"), _class2.prototype)), _class2)) || _class);
  _exports.default = Span;
});