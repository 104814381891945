define("ember-cli-ifa/services/asset-map", ["exports", "@ember/service", "@ember/object"], function (_exports, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _service.default.extend({
    enabled: false,
    map: (0, _object.computed)(() => ({})),
    prepend: '/',
    fullMap: (0, _object.computed)('map', function () {
      const map = (0, _object.get)(this, 'map');
      const ret = {};
      Object.keys(map).forEach(k => {
        const v = map[k];
        ret[k] = v;
        ret[v] = v;
      });
      return ret;
    }),
    resolve(name) {
      const fullMap = (0, _object.get)(this, 'fullMap') || {};
      const prepend = (0, _object.get)(this, 'prepend');
      const enabled = (0, _object.get)(this, 'enabled');
      const assetName = enabled ? fullMap[name] || name : name;
      return "".concat(prepend).concat(assetName);
    }
  });
  _exports.default = _default;
});