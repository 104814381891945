define("@trixie-html/addon-utils/helpers/size-doc", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const KILOBYTE_SIZE = 1024;
  const MEGABYTE_SIZE = KILOBYTE_SIZE * KILOBYTE_SIZE;
  function calculateSizeDoc(value) {
    if ((0, _utils.isEmpty)(value)) {
      return '';
    }
    const size = parseInt(value, 10);
    if (size < KILOBYTE_SIZE) {
      return "".concat(size, " bytes");
    }
    if (size < MEGABYTE_SIZE) {
      return "".concat((size / KILOBYTE_SIZE).toFixed(), " Kb");
    }
    return "".concat((size / MEGABYTE_SIZE).toFixed(), " Mb");
  }
  var _default = (0, _helper.helper)(params => calculateSizeDoc(params[0]));
  _exports.default = _default;
});