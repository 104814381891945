define("@trixie-html/core-session/resources/public-enax", ["exports", "@trixie-html/core-session/resources/session-enax", "@trixie-html/core-session/decorators/session", "@ember/service", "@ember/object"], function (_exports, _sessionEnax, _session, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let PublicEnaxResource = (_dec = (0, _session.default)('public'), (_class = class PublicEnaxResource extends _sessionEnax.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentSession", _descriptor, this);
      _initializerDefineProperty(this, "provider", _descriptor2, this);
    }
    async before() {
      let {
        currentSession
      } = this;
      if (!currentSession) {
        currentSession = await this.provider.pull('session', 'loginWithOAuth', {
          app: 'public'
        });
      }
      this.headers.tsec = (0, _object.get)(currentSession, 'tsec');
      this.headers.ContactId = (0, _object.get)(currentSession, 'contactId');
      return super.before(...arguments);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = PublicEnaxResource;
});