define("@trixie-html/core-session/repositories/configuration", ["exports", "ember-object-data-model/repositories/repository", "ember-utils/utils/banking/identification-unformatter", "@trixie-html/core-session/decorators/session", "@ember/service", "@ember/object", "ember-object-data-model/decorators/depends-on"], function (_exports, _repository, _identificationUnformatter, _session, _service, _object, _dependsOn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const UNIQ_ID = 'XXXX';
  let ConfigurationRepository = (_dec = (0, _session.default)('client'), _dec2 = (0, _dependsOn.default)('intl.primaryLocale'), _dec3 = (0, _dependsOn.default)('intl.primaryLocale'), (_class = class ConfigurationRepository extends _repository.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "gam", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _initializerDefineProperty(this, "slinky", _descriptor3, this);
      _initializerDefineProperty(this, "userStorage", _descriptor4, this);
      _initializerDefineProperty(this, "currentSession", _descriptor5, this);
    }
    async getPublicConfiguration() {
      let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const lastClient = await this.callRepository('customer', 'getLastClient');
      if (lastClient.endDoc) {
        params.endDoc = lastClient.endDoc;
      }
      if ((0, _object.get)(lastClient, 'username')) {
        params.document = (0, _identificationUnformatter.default)((0, _object.get)(lastClient, 'username'));
      }
      const {
        data
      } = await this.gam.POST('public-config', {
        endDoc: params.endDoc,
        document: params.document
      });
      return this.createEntry('configuration', UNIQ_ID, data);
    }
    async getPrivateConfiguration(sessionObj) {
      let data;
      const device = await this.provider.pull('device', 'getDevice');
      if (device.isNative) {
        const nativePrivateConfig = await this.slinky.send('Configuration.getPrivateResponse');
        data = nativePrivateConfig.result;
      } else {
        const customer = await this.provider.pull('customer', 'getContextualData');
        const application = await this.provider.pull('application', 'getApplication');
        const response = await this.gam.POST('private-config', {}, {
          session: sessionObj || this.currentSession,
          customer,
          previousAppVersion: (0, _object.get)(application, 'previousVersion')
        });
        data = response.data;
      }
      return this.createEntry('configuration', (0, _object.get)(sessionObj || this.currentSession, 'username'), data);
    }
    async getPrivateConfigurationHTML(sessionObj) {
      const customer = await this.provider.pull('customer', 'getContextualDataHTML');
      const application = await this.provider.pull('application', 'getApplication');
      const response = await this.gam.POST('private-config', {}, {
        session: sessionObj || this.currentSession,
        customer,
        previousAppVersion: (0, _object.get)(application, 'previousVersion')
      });
      return this.createEntry('configuration', (0, _object.get)(sessionObj || this.currentSession, 'username'), response === null || response === void 0 ? void 0 : response.data);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "gam", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "slinky", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userStorage", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentSession", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "getPrivateConfiguration", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "getPrivateConfiguration"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "getPrivateConfigurationHTML", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "getPrivateConfigurationHTML"), _class.prototype)), _class));
  _exports.default = ConfigurationRepository;
});