define("@trixie-html/core-semaas/models/semaas", ["exports", "ember-object-data-model/models/base", "@ember/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SemaasModel extends _base.default {
    get isValid() {
      const now = new Date().getTime();
      return (0, _object.get)(this, 'expiresAt') > now;
    }
  }
  _exports.default = SemaasModel;
});