define("ember-collector-dispatcher/constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MAX_TIMEOUT = _exports.MAX_CONCURRENT = void 0;
  const MAX_TIMEOUT = 30000;
  _exports.MAX_TIMEOUT = MAX_TIMEOUT;
  const MAX_CONCURRENT = 50;
  _exports.MAX_CONCURRENT = MAX_CONCURRENT;
});