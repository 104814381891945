define("@trixie-html/core-session/models/session/private", ["exports", "@ember/object", "@ember/object/observers", "@trixie-html/core-session/models/session/base", "@trixie-html/core-session/decorators/-session-inactivity"], function (_exports, _object, _observers, _base, _sessionInactivity) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.WINDOWS = _exports.TIMEOUTS = void 0;
  var _dec, _class, _class2;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const TIMEOUTS = {
    ENAX: 300000,
    // 5 minutes
    INACTIVITY: 290000 // ~4.8 minutes
  };
  _exports.TIMEOUTS = TIMEOUTS;
  const WINDOWS = {
    ENAX: 120000 // 2 minutes
  };
  _exports.WINDOWS = WINDOWS;
  const TIMER_ID = 'enax';
  let PrivateModel = (_dec = (0, _sessionInactivity.default)(TIMEOUTS.INACTIVITY), _dec(_class = (_class2 = class PrivateModel extends _base.default {
    constructor() {
      super(...arguments);
      (0, _observers.addObserver)(this, 'tsec', this, '_tsecDidChange');
    }
    async willDestroy() {
      (0, _observers.removeObserver)(this, 'tsec', this, '_tsecDidChange');
      super.willDestroy(...arguments);
    }
    _tsecDidChange() {
      if ((0, _object.get)(this, 'tsec')) {
        this.getTimer(TIMER_ID).run();
      } else {
        // Don't do a "logout", user can continue using the app without calling the backend.
        this._clearTimers();
      }
    }
    _createTimers() {
      super._createTimers(...arguments);
      this._createEnaxTimer();
    }
    _createEnaxTimer() {
      this._createTimer({
        id: TIMER_ID,
        callback: this._onEnaxTimeout,
        delay: TIMEOUTS.ENAX - WINDOWS.ENAX
      });
    }
    _onEnaxTimeout() {
      if (!(this.isDestroying || this.isDestroyed)) {
        this.keepAlive();
      }
    }
    keepAlive() {
      super.keepAlive(...arguments);
      this.getTimer(TIMER_ID).run();
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "_onEnaxTimeout", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "_onEnaxTimeout"), _class2.prototype)), _class2)) || _class);
  _exports.default = PrivateModel;
});