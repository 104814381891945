define("@trixie-html/core-traceability/utils/page-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PAGE_AREA_CODES = void 0;
  _exports.default = pageArea;
  const PAGE_AREA_CODES = {
    PRIVATE: 'privada',
    PUBLIC: 'publica',
    UNKNOWN: '?'
  };
  _exports.PAGE_AREA_CODES = PAGE_AREA_CODES;
  function pageArea(isLogged) {
    if (typeof isLogged === 'boolean') {
      return isLogged ? PAGE_AREA_CODES.PRIVATE : PAGE_AREA_CODES.PUBLIC;
    }
    return PAGE_AREA_CODES.UNKNOWN; // In some rare cases might not know if the user is logged
  }
});