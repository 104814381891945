define("@trixie-html/core-session/resources/client-enax", ["exports", "@trixie-html/core-session/resources/session-enax", "@ember/utils", "@ember/array", "@ember/object", "rsvp"], function (_exports, _sessionEnax, _utils, _array, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SIGNATURE_TYPES = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  const HTTP_FORBIDDEN = 403;
  const SIGNATURE_TYPES = {
    ACCESS_PASSWORD: '02',
    SMS: '05',
    VOICE: '35',
    CHALLENGE_ANSWER: '132'
  };
  _exports.SIGNATURE_TYPES = SIGNATURE_TYPES;
  const SIGNATURE_PRIORITIES = [SIGNATURE_TYPES.CHALLENGE_ANSWER, SIGNATURE_TYPES.SMS, SIGNATURE_TYPES.VOICE, SIGNATURE_TYPES.ACCESS_PASSWORD];
  const SIGNATURE_MODALS = {
    [SIGNATURE_TYPES.ACCESS_PASSWORD]: 'accessPasswordModal',
    [SIGNATURE_TYPES.CHALLENGE_ANSWER]: 'otpTokenSoftwareModal',
    [SIGNATURE_TYPES.SMS]: 'otpSmsModal',
    [SIGNATURE_TYPES.VOICE]: 'otpVoiceModal'
  };
  const BIOMETRICS_LEVEL_CHANGE_EXCEPTIONS = ['SMC201500296', 'SMC201910159', 'SMC201700241', 'SMC201500268', 'SMGG20200106'];
  function getAuthenticationHeaders(headers) {
    const authenticationType = headers.get('authenticationtype');
    const authenticationData = headers.get('authenticationdata');
    const authenticationState = headers.get('authenticationstate');
    const authenticationChallenge = headers.get('authenticationchallenge');
    return {
      authenticationType,
      authenticationData,
      authenticationState,
      authenticationChallenge
    };
  }
  function getSMC(response) {
    let smc = response.resource.smc;
    if (typeof smc === 'object') {
      smc = smc[response.request.method];
    }
    return smc;
  }
  function getResponsePairs(responseTypes, responseData) {
    const knownTypes = Object.values(SIGNATURE_TYPES);
    const responsePairs = responseTypes.map((type, index) => ({
      type,
      data: responseData[index]
    }));
    const knownResponsePairs = responsePairs.filter(pair => knownTypes.includes(pair.type));
    return (0, _array.A)(knownResponsePairs);
  }
  async function _findAsync(array, callback) {
    if (!array || !array.length) {
      throw new Error('Item not found');
    }
    async function iterate(index) {
      const item = array[index];
      if (!item) {
        throw new Error('Item not found');
      }
      return (await callback(item)) ? item : iterate(++index);
    }
    return await iterate(0);
  }
  function buildHeaders(headers, type, state, challenge) {
    // Add new type.
    headers.authenticationtype = type;

    // Remove data.
    delete headers.authenticationdata;

    // Append state if exists.
    if (state) {
      headers.authenticationstate = state;
    }
    if (challenge) {
      headers.authenticationchallenge = challenge;
    }
  }
  function resetHeaders(headers) {
    delete headers.authenticationtype;
    delete headers.authenticationdata;
    delete headers.authenticationstate;
  }
  function updateKnownResponsePairs(knownResponsePairs, responsePairs) {
    responsePairs.forEach(_ref => {
      let {
        type,
        data
      } = _ref;
      const knownPair = knownResponsePairs.findBy('type', type);
      knownPair.data = data;
    });
  }
  function retryLevelChange(labelConfirm) {
    return {
      retryLevelChange: true,
      labelConfirm
    };
  }
  class ClientEnax extends _sessionEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "otpSmsModal", 'sms');
      _defineProperty(this, "otpVoiceModal", 'voice');
      _defineProperty(this, "otpTokenSoftwareModal", 'token-software');
      _defineProperty(this, "accessPasswordModal", 'access-password');
    }
    get privateConfig() {
      return this.provider.pull('configuration', 'getPrivateConfiguration');
    }
    async openLevelChange(response) {
      const {
        authenticationType,
        authenticationData,
        authenticationState,
        authenticationChallenge
      } = getAuthenticationHeaders(response.headers);
      if (!authenticationType) {
        throw new Error('You have not enough authorization to fulfill this request with the current security token.');
      }

      // Split by ';'
      const responseTypes = authenticationType.split(';');
      const responseData = authenticationData.split(';');
      if (responseTypes.includes(SIGNATURE_TYPES.CHALLENGE_ANSWER)) {
        await this._handleBiometricsExceptions(response, responseTypes, responseData);
      }

      // Get all known response pairs.
      const responsePairs = getResponsePairs(responseTypes, responseData);
      let knownResponsePairs = this.knownResponsePairs;

      // Assign new response pairs.
      if (!knownResponsePairs) {
        knownResponsePairs = responsePairs;
      } else {
        // Update known response pairs.
        updateKnownResponsePairs(knownResponsePairs, responsePairs);
      }
      // Save known response pairs.
      this.knownResponsePairs = knownResponsePairs;
      if ((0, _utils.isEmpty)(knownResponsePairs)) {
        throw new Error("Unhandled authorizations types '".concat(responseTypes.join(', '), "'."));
      }

      // Select type using priority list.
      const selectedPair = await this.selectKnownResponsePair(responsePairs);
      const {
        type,
        data
      } = selectedPair;

      // Build headers
      buildHeaders(this.headers, type, authenticationState, authenticationChallenge);

      // When data is empty means that we need to request for a data sending selected type (f.e.: sms, voice.).
      if ((0, _utils.isEmpty)(data)) {
        return this.retry();
      }
      return this._handleLevelChangeModal(type, data, knownResponsePairs);
    }

    /* istanbul ignore next */
    async _handleBiometricsExceptions(response, responseTypes, responseData) {
      const privateConfig = await this.privateConfig;
      const skipBiometrics = (0, _object.get)(privateConfig, 'panicBiometricSignxception.isActive');
      const skipBiometricsForSMC = (0, _object.get)(privateConfig, 'biometricSignException.isActive') && BIOMETRICS_LEVEL_CHANGE_EXCEPTIONS.includes(getSMC(response));
      if (skipBiometrics || skipBiometricsForSMC) {
        const index = responseTypes.findIndex(type => type === SIGNATURE_TYPES.CHALLENGE_ANSWER);
        if (index >= 0) {
          responseTypes.splice(index, 1);
          responseData.splice(index, 1);
        }
      }
    }
    async selectKnownResponsePair(responseTypes) {
      if (responseTypes.length === 1) {
        const pair = this.knownResponsePairs.findBy('type', responseTypes[0].type);
        if (pair) {
          return pair;
        }
        throw new Error("unknown response type '".concat(responseTypes[0], "'"));
      }
      const priorityType = await _findAsync(SIGNATURE_PRIORITIES, async type => {
        const pair = this.knownResponsePairs.findBy('type', type);
        if (!pair) {
          return false;
        }
        if (type === SIGNATURE_TYPES.CHALLENGE_ANSWER) {
          const tokenSoftware = await this.provider.pull('token-software', 'getTokenSoftware');
          const deviceToken = await (0, _object.get)(tokenSoftware, 'deviceToken');
          if (!(0, _object.get)(deviceToken, 'isActive')) {
            return false;
          }
          const isActive = await (0, _object.get)(tokenSoftware, 'isActive');

          // Notify the user easy sign is activated in another device
          if (!isActive) {
            await deviceToken.delete().catch(() => {});
            return false;
          }
          return isActive;
        }
        return true;
      });
      const pair = this.knownResponsePairs.findBy('type', priorityType);
      return pair ? (0, _rsvp.resolve)(pair) : (0, _rsvp.reject)(new Error());
    }
    async _handleLevelChangeModal(type, data, responsePairs) {
      const modalOptions = {
        factory: 'ENAX',
        resource: this,
        type,
        data,
        responsePairs
      };
      const modalProperty = SIGNATURE_MODALS[type];
      const modalName = this[modalProperty];
      if (!modalName) {
        throw new Error("Unknown modal for known type '".concat(type, "'."));
      }
      const ret = await this.modal.open(modalName, modalOptions);
      if (ret) {
        // Change selected signature type.
        if (ret.selectSignatureType) {
          buildHeaders(this.headers, ret.selectSignatureType);
          return this.retry();
        }

        // On retry level change, confirm and resend request.
        // This params are sent on retryLevelChange method.
        if (ret.retryLevelChange) {
          resetHeaders(this.headers);
          const retryConfirm = await this.modal.open('confirm', {
            messages: [{
              title: 'error.signature'
            }, {
              title: ret.labelConfirm
            }],
            labelCancel: 'label.cancel',
            labelOk: 'label.retry',
            verticalButtons: true
          });
          if (retryConfirm) {
            return this.retry();
          }
        }
      }
      return ret;
    }

    // eslint-disable-next-line complexity, max-statements
    handleGlobalFail(response) {
      const data = response.data;
      const {
        code
      } = data.messages[0];
      const responseAuthType = response.headers.get('authenticationtype');
      const requestAuthData = (0, _object.get)(this, 'headers.authenticationdata');
      switch (code) {
        case 'stateChangeGoOn':
        case 'forbidden':
          if (responseAuthType) {
            return {
              promise: this.openLevelChange(response)
            };
          }
          break;
        case 'invalidProcess':
          return {
            promise: retryLevelChange('error.signatureTimeout')
          };
        case 'badRequest':
          if (requestAuthData && requestAuthData.match(/password/)) {
            return {
              alert: 'error.wrongData'
            };
          }
          break;
        case 'invalidCredentials':
          if (requestAuthData) {
            // OTP_INCORRECTO
            if (requestAuthData.match(/otp/)) {
              return {
                alert: 'error.wrongOtp'
              };
            }

            // PASSWORD_INCORRECTA
            if (requestAuthData.match(/password/)) {
              return {
                alert: 'error.wrongData'
              };
            }
          }
          break;
        default:
        // nope
      }

      return super.handleGlobalFail(response);
    }
    handleLocalFail(response) {
      const data = response.data;
      const errorCode = data['error-code'];
      const numErrorCode = parseInt(errorCode, 10);

      // ENAX error control.
      // DOC: https://docs.google.com/a/bbva.com/file/d/0B772gIBcnNCbY1V1bU1PNzJNLVk/edit
      // Error-code is a string!
      switch (numErrorCode) {
        // 70 NO AUTORIZADO
        // Es necesario ponerse en contacto con la administración de la arquitectura
        // para iniciar un proceso administrativo de autorización de uso el servicio.
        // También salta en el primer paso de una firma de operaciones, en cuyo caso
        // vendrá acompañado de los tipos de firma permitidos.
        case 70:
          return {
            promise: this.openLevelChange(response)
          };

        // 999 Error, el proceso de firma continúa
        case 999:
          return {
            promise: this.openLevelChange(response)
          };

        // 362 ESTADO_AUTENTICACION_MULTIPASO_CADUCADO
        // El estado de la autenticación multipaso ha caducado
        case 362:
          return {
            promise: retryLevelChange('error.signatureTimeout')
          };

        // 363 ESTADO_AUTENTICACION_MULTIPASO_ANULADO_POR_REINTENTOS
        // El estado de la autenticación multipaso ha sido anulado por reintentos.
        case 363:
          return {
            promise: retryLevelChange('error.signatureMaxRetries')
          };
        // 168
        // El otp no coincide con el generado
        case 168:
          return {
            alert: 'error.wrongOtp'
          };
        default:
        // nope
      }

      // Handle 403 with authenticationdata filled.
      if (response.status === HTTP_FORBIDDEN && (0, _object.get)(this, 'headers.authenticationdata')) {
        return {
          alert: 'error.wrongData'
        };
      }
      return super.handleLocalFail(response);
    }
  }
  _exports.default = ClientEnax;
});