define("ember-storages/storages/session", ["exports", "ember-storages/storages/local"], function (_exports, _local) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Implementation of memory storage on local storage.
   *
   * @extends LocalStorage
   * @uses StorageMixin
   */
  var _default = _local.default.extend({
    /**
     * Storage adapter.
     *
     * @property adapter
     * @type {Object}
     * @default window.localStorage
     */
    adapter: window.sessionStorage,
    /**
     * Namespace to serialize keys.
     *
     * @property namespace
     * @type String
     * @default 'session'
     */
    namespace: 'session'
  });
  _exports.default = _default;
});