define("@woody-mrs-potato/core-transitions/services/liquid-fire", ["exports", "@ember/service", "@ember/object", "@ember/runloop", "@glimmer/tracking", "@woody-mrs-potato/core-transitions/decorators/evented"], function (_exports, _service, _object, _runloop, _tracking, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _class2, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const TRANSITION_TIMEOUT = 1500;
  let LiquidFireService = (0, _evented.default)(_class = (_class2 = class LiquidFireService extends _service.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "liquidFireTransitions", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "isTransitioning", _descriptor3, this);
      this.router.on('routeWillChange', this.routeWillChange.bind(this));
      this.on('transitionEnd', () => this.onTransitionEnd());
    }
    _catchTransition(transition) {
      //  We make sure that is not a transition.abort, caused
      //  by redirect, transitionTo in beforeModel etc.
      const {
        isCausedByAbortingTransition,
        isAborted,
        targetName,
        router,
        error
      } = transition;
      const activeTransition = router && (0, _object.get)(router, 'activeTransition');
      const activeTransitionTargetName = activeTransition && (0, _object.get)(activeTransition, 'targetName');
      const isRedirect = !error && !isCausedByAbortingTransition && isAborted && activeTransitionTargetName && targetName !== activeTransitionTargetName;
      if (isRedirect) {
        activeTransition.catch(this._catchTransition.bind(this, activeTransition));
      } else {
        this.isTransitioning = false;
      }
    }
    routeWillChange(transition) {
      this.isTransitioning = true;
      transition.catch(this._catchTransition.bind(this, transition));
      // The animations are not fired on route refresh
      // https://github.com/ember-animation/liquid-fire/issues/473
      this._timer = (0, _runloop.debounce)(this, 'trigger', 'transitionEnd', TRANSITION_TIMEOUT);
    }
    onTransitionEnd() {
      this.isTransitioning = false;
      (0, _runloop.cancel)(this._timer);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "liquidFireTransitions", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "isTransitioning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class2)) || _class;
  _exports.default = LiquidFireService;
});