define("@trixie-html/addon-discreet-mode/helpers/discreet-format-upper-currency", ["exports", "@trixie-html/addon-discreet-mode/helpers/discreet-format-number"], function (_exports, _discreetFormatNumber) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class DiscreetFormatUpperCurrency extends _discreetFormatNumber.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "defaultCurrency", 'EUR');
      _defineProperty(this, "defaultSize", 'txt_m');
    }
  }
  _exports.default = DiscreetFormatUpperCurrency;
});