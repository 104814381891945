define("@trixie-html/core-traceability/events/crm-view", ["exports", "@trixie-html/core-traceability/events/link"], function (_exports, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CrmViewTealiumEvent extends _link.default {
    constructor(payload) {
      super(payload);
    }
  }
  _exports.default = CrmViewTealiumEvent;
});