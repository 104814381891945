define("@trixie-html/core-traceability/events/view", ["exports", "@trixie-html/core-traceability/events/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class ViewTealiumEvent extends _base.default {
    constructor(payload) {
      super('view');
      this.payload = payload;
    }
  }
  _exports.default = ViewTealiumEvent;
});