define("ember-lazy-mount/components/lazy-mount/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xbgw77mP",
    "block": "[[[41,[30,0,[\"isLoading\"]],[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"isLoading\",\"error\"],[true,null]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"error\"]],[[[1,\"  \"],[18,1,[[28,[37,2],null,[[\"isLoading\",\"error\"],[false,[30,0,[\"error\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[46,[28,[37,4],[[30,0,[\"loadedName\"]]],[[\"model\"],[[30,0,[\"model\"]]]]],null,null,null],[1,\"\\n\"]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"yield\",\"hash\",\"component\",\"-mount\"]]",
    "moduleName": "ember-lazy-mount/components/lazy-mount/template.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});