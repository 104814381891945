define("@trixie-html/core-session/enaxs/granting-tickets-oauth", ["exports", "@trixie-html/core-network/resources/enax", "@woody-mrs-potato/core-http/services/http", "ember-utils/utils/math/xor"], function (_exports, _enax, _http, _xor) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class GrantingTicketsOAuthEnax extends _enax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "smc", 'SMC201400324');
      _defineProperty(this, "endpoint", '/TechArchitecture/grantingTicketsOauth/V01');
      _defineProperty(this, "credentials", 'include');
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_ONLY);
      _defineProperty(this, "data", {
        grant_type: 'client_credentials'
      });
      _defineProperty(this, "contentType", 'application/x-www-form-urlencoded');
      _defineProperty(this, "acceptType", null);
    }
    get authorization() {
      const {
        userId,
        M1,
        M2
      } = this;
      const key = (0, _xor.default)(M1, M2);
      return btoa("".concat(userId, ":").concat(key));
    }
    before() {
      this.merge({
        headers: {
          authorization: "Basic ".concat(this.authorization)
        }
      });
      return super.before(...arguments);
    }
  }
  _exports.default = GrantingTicketsOAuthEnax;
});