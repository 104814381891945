define("@trixie-html/core-traceability/normalizers/v4-0", ["exports", "@ember/object", "ember-copy", "@ember/utils"], function (_exports, _object, _emberCopy, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MIN_STEP = _exports.MAX_STEP = void 0;
  const MIN_STEP = 2;
  _exports.MIN_STEP = MIN_STEP;
  const MAX_STEP = 25;
  _exports.MAX_STEP = MAX_STEP;
  class V40Normalizer extends _object.default {
    _pageActivity(data) {
      data.video = {
        nameOfVideoDisplayed: data.video.attributes[0].nameOfVideoDisplayed
      };
      data.audio = {
        nameOfPodcastDisplayed: data.audio.attributes[0].nameOfPodcastDisplayed
      };
      return data;
    }
    _user(data) {
      data.segment = data.segment[0];
      data.gender = '';
      return data;
    }
    _applicationState(_ref) {
      let {
        state,
        step
      } = _ref;
      if (!state) {
        const _step = parseInt(step, 10);
        if (Number.isInteger(_step) && step >= MIN_STEP && step <= MAX_STEP) {
          return "app step ".concat(_step);
        }
        return '';
      }
      return state;
    }
    normalize(eventData) {
      const data = (0, _emberCopy.copy)(eventData, true);
      data.versionDL = '20190718_4.0';
      data.application.state = this._applicationState(data.application);
      data.page.pageInfo.pageIntent = (0, _utils.isEmpty)(data.page.pageInfo.pageIntent) ? 'informacion' : data.page.pageInfo.pageIntent;
      data.page.pageInfo.siteAppName = "".concat(data.page.pageInfo.siteAppName, " ").concat(data.page.pageInfo.sysEnv);
      data.page.pageInfo.pageName = "".concat(data.page.pageInfo.sysEnv, ":").concat(data.page.pageInfo.pageName);
      data.page.pageActivity = this._pageActivity(data.page.pageActivity);
      data.user = this._user(data.user);
      return data;
    }
  }
  _exports.default = V40Normalizer;
});