define("ember-cli-ifa/initializers/asset-map", ["exports", "rsvp", "ember-cli-ifa/services/asset-map", "@ember/utils", "ember-cli-ifa/utils/get-asset-map-data"], function (_exports, _rsvp, _assetMap, _utils, _getAssetMapData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(app) {
    let assetMapFile = (0, _getAssetMapData.default)();
    if (!assetMapFile) {
      app.register('service:asset-map', _assetMap.default);
      return;
    }
    if ((0, _utils.typeOf)(assetMapFile) === 'object' && assetMapFile.assets) {
      _assetMap.default.reopen({
        map: assetMapFile.assets,
        prepend: assetMapFile.prepend,
        enabled: true
      });
      app.register('service:asset-map', _assetMap.default);
    } else {
      app.deferReadiness();
      const promise = fetch(assetMapFile).then(response => response.json());
      promise.then(function () {
        let map = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        _assetMap.default.reopen({
          map: map.assets,
          prepend: map.prepend,
          enabled: true
        });
      }).then(() => {
        app.register('service:asset-map', _assetMap.default);
      }).catch(err => {
        console.error('Failed to register service:asset-map', err);
      }).finally(() => {
        app.advanceReadiness();
      });
    }
  }
  var _default = {
    name: 'asset-map',
    initialize
  };
  _exports.default = _default;
});