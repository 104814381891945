define("ember-alerter/components/alert-item", ["exports", "@ember/component", "@ember/service", "@ember/runloop", "@ember/object"], function (_exports, _component, _service, _runloop, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-observers, no-magic-numbers */
  /**
   * Component that extends functionality of alert.
   *
   * @extends Ember.Component
   */
  var _default = _component.default.extend({
    /**
     * Service injection.
     *
     * @property alerter
     * @type Object
     */
    alerter: (0, _service.inject)(),
    /**
     * When isShown property is changed, handles the duration and the life of the alert.
     *
     * @method isShownDidChange
     */
    isShowDidChange: (0, _object.observer)('model.isShown', function () {
      const alertShow = this.get('model.isShown');
      const wasShown = this.get('model.wasShown');
      const duration = this.get('model.duration');

      // Update last value.
      if (this.get('model')) {
        this.set('model.wasShown', alertShow);
      }
      if (alertShow && !wasShown) {
        // If is shown, run timer to not show.
        if (!this.get('model.isPermanent')) {
          (0, _runloop.later)(this, function () {
            /* istanbul ignore else  */
            if (!this.isDestroyed) {
              this.set('model.isShown', false);
            }
          }, duration);
        }
      } else if (wasShown) {
        // If is not shown anymore, run timer to remove it from parent.
        (0, _runloop.later)(this, function () {
          if (!this.isDestroyed) {
            this.get('alerter').clear(this.get('model'));
          }
        }, 500);
      }
    }),
    /**
     * Disables visibility of the alert.
     *
     * @method click
     * @param {Object} e
     */
    click(e) {
      this._super(e);
      if (this.get('model.isShown')) {
        this.set('model.isShown', false);
      }
    },
    /**
     * didReceiveAttrs hook.
     *
     * @method didReceiveAttrs
     */
    didReceiveAttrs() {
      this._super();

      // If is first time show alert.
      (0, _runloop.later)(this, function () {
        /* istanbul ignore else  */
        if (!this.isDestroyed && this.get('model')) {
          this.set('model.isShown', true);
        }
      }, 50);
    }
  });
  _exports.default = _default;
});