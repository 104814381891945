define("@trixie-html/core-traceability/utils/get-adapter-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getAdapterConfig;
  function getAdapterConfig(config, adapterName) {
    var _config$metricsAdapte;
    if (!adapterName) {
      return null;
    }
    return config === null || config === void 0 ? void 0 : (_config$metricsAdapte = config.metricsAdapters) === null || _config$metricsAdapte === void 0 ? void 0 : _config$metricsAdapte.find(_ref => {
      let {
        name
      } = _ref;
      return name === adapterName;
    });
  }
});