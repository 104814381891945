define("@trixie-html/core-session/enaxs/granting-tickets", ["exports", "@trixie-html/core-network/resources/enax", "@woody-mrs-potato/core-http/services/http"], function (_exports, _enax, _http) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class GrantingTicketsEnax extends _enax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "endpoint", '/TechArchitecture/grantingTickets/V02');
      _defineProperty(this, "smc", 'SMC201400151');
      _defineProperty(this, "cacheStrategy", _http.CACHE_STRATEGIES.NETWORK_ONLY);
    }
    get credentials() {
      if (this.method === 'POST') {
        return 'include';
      }
      return 'same-origin';
    }
  }
  _exports.default = GrantingTicketsEnax;
});