define("@trixie-html/core-session/enaxs/delete-software-token", ["exports", "@trixie-html/core-session/resources/client-enax"], function (_exports, _clientEnax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  class DeleteSoftwareTokenEnax extends _clientEnax.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "smc", 'SMGG20200106');
      _defineProperty(this, "endpoint", '/security/v1/software-tokens/:id');
    }
  }
  _exports.default = DeleteSoftwareTokenEnax;
});