define("@trixie-html/core-session/components/modal-token-software", ["exports", "@ember/object", "@ember/service", "@trixie-html/core-foundation/components/modal", "@trixie-html/core-session/resources/client-enax", "@trixie-html/core-session/templates/modal-token-software", "@glimmer/tracking"], function (_exports, _object, _service, _modal, _clientEnax, _modalTokenSoftware, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const HTTP_CONFLICT = 409;
  let ModalTokenSoftware = (_class = class ModalTokenSoftware extends _modal.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "layout", _modalTokenSoftware.default);
      _initializerDefineProperty(this, "veil", _descriptor, this);
      _initializerDefineProperty(this, "alerter", _descriptor2, this);
      _initializerDefineProperty(this, "intl", _descriptor3, this);
      _initializerDefineProperty(this, "provider", _descriptor4, this);
      _initializerDefineProperty(this, "isDisabled", _descriptor5, this);
      _initializerDefineProperty(this, "isSmsEnabled", _descriptor6, this);
      _initializerDefineProperty(this, "isVoiceEnabled", _descriptor7, this);
      _initializerDefineProperty(this, "tokenSoftware", _descriptor8, this);
    }
    get signAlternateMethod() {
      return this.isVoiceEnabled || this.isSmsEnabled;
    }
    async didOpen() {
      super.didOpen(...arguments);
      this.tokenSoftware = await this.provider.pull('token-software', 'getTokenSoftware');
      this.isDisabled = false;
      this.send('signTransaction');
    }
    async _sendToken(otp) {
      const options = this.model.options;
      const resource = options.resource;
      if (options.factory === 'ENAX') {
        (0, _object.set)(resource, 'headers.authenticationdata', "otp-token=".concat(otp));
      }
      resource.veil = true;
      resource.error = true;
      const response = await resource.retry();
      return this.close(response);
    }
    async showOtherMethods() {
      let disableRetry = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      const knownResponsePairs = this.model.options.resource.knownResponsePairs;
      const hasSmsType = knownResponsePairs.findBy('type', _clientEnax.SIGNATURE_TYPES.SMS);
      const hasVoiceType = knownResponsePairs.findBy('type', _clientEnax.SIGNATURE_TYPES.VOICE);
      const privateConfig = await this.provider.pull('configuration', 'getPrivateConfiguration');
      const voiceOtpIsEnabled = (0, _object.get)(privateConfig, 'otpFallback.isActive');
      this.model.options.resource.veil = true;
      this.model.options.resource.error = true;
      this.isDisabled = disableRetry;
      this.isSmsEnabled = hasSmsType;
      this.isVoiceEnabled = voiceOtpIsEnabled && hasVoiceType;
    }
    sendSmsOtp() {
      this.close({
        selectSignatureType: _clientEnax.SIGNATURE_TYPES.SMS
      });
    }
    sendVoiceOtp() {
      this.close({
        selectSignatureType: _clientEnax.SIGNATURE_TYPES.VOICE
      });
    }
    async signTransaction() {
      const {
        tokenSoftware
      } = this;
      const challenge = this.model.options.resource.headers.authenticationchallenge;
      try {
        this.veil.add();
        this.isDisabled = true;
        const deviceToken = await (0, _object.get)(tokenSoftware, 'deviceToken');
        const {
          value
        } = await deviceToken.generate(challenge);
        await this._sendToken(value);
        this.isDisabled = false;
      } catch ({
        code,
        status
      }) {
        const isSigningWithFaceId = await (0, _object.get)(tokenSoftware, 'isSigningWithFaceId');
        if (code === 'CANCELED' && !isSigningWithFaceId) {
          this.isDisabled = false;
          return;
        }
        if (status !== HTTP_CONFLICT) {
          this.showOtherMethods(!isSigningWithFaceId);
        }
        const errorText = this.intl.t('error.signatureEasySign');
        this.alerter.add({
          text: errorText,
          type: 'float',
          visualType: 'error',
          accesibilityText: errorText
        });
      } finally {
        this.veil.remove();
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "veil", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "alerter", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "provider", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isDisabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isSmsEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isVoiceEnabled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "tokenSoftware", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "sendSmsOtp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendSmsOtp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sendVoiceOtp", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sendVoiceOtp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "signTransaction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "signTransaction"), _class.prototype)), _class);
  _exports.default = ModalTokenSoftware;
});