define("ember-modal-service/models/modal", ["exports", "@ember/object/computed", "@ember/object", "@ember/string", "rsvp", "@ember/utils", "@ember/object/promise-proxy-mixin", "@glimmer/tracking"], function (_exports, _computed, _object, _string, _rsvp, _utils, _promiseProxyMixin, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let ModalModel = (_dec = (0, _computed.oneWay)('_deferred.promise'), _dec2 = (0, _object.computed)('name'), (_class = class ModalModel extends _object.default.extend(_promiseProxyMixin.default) {
    constructor() {
      super(...arguments);
      _defineProperty(this, "name", null);
      _defineProperty(this, "options", null);
      _initializerDefineProperty(this, "isPending", _descriptor, this);
      _initializerDefineProperty(this, "isSettled", _descriptor2, this);
      _initializerDefineProperty(this, "isFulfilled", _descriptor3, this);
      _initializerDefineProperty(this, "isRejected", _descriptor4, this);
      _initializerDefineProperty(this, "_deferred", _descriptor5, this);
      _initializerDefineProperty(this, "promise", _descriptor6, this);
    }
    get fullname() {
      const name = this.name;
      if ((0, _utils.isBlank)(name)) {
        throw new Error('Modal must have a name.');
      }
      return "modal-".concat((0, _string.dasherize)(name));
    }
    init() {
      super.init(...arguments);
      this._deferred = (0, _rsvp.defer)("Modal: open '".concat(this.fullname, "'"));
    }
    resolve() {
      this.isPending = false;
      this.isSettled = true;
      this.isFulfilled = true;
      this.isRejected = false;
      return this._deferred.resolve(...arguments);
    }
    reject() {
      this.isPending = false;
      this.isSettled = true;
      this.isFulfilled = false;
      this.isRejected = true;
      return this._deferred.reject(...arguments);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isPending", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isSettled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isFulfilled", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isRejected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_deferred", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "promise", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fullname", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "fullname"), _class.prototype)), _class));
  _exports.default = ModalModel;
});