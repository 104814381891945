define("@trixie-html/addon-tasks/normalizers/task", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class TaskNormalizer extends _object.default {
    normalize(properties) {
      delete properties.content;
      return {
        name: 'task',
        properties
      };
    }
  }
  _exports.default = TaskNormalizer;
});