define("ember-google-maps/modifiers/g-map/did-insert", ["exports", "ember-modifier", "@ember/debug"], function (_exports, _emberModifier, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function gMapDidInsert(element, _ref, named) {
    let [callback, ...positional] = _ref;
    (false && !(typeof callback === 'function') && (0, _debug.assert)('`g-map/did-insert` expects a function as its first positional argument.', typeof callback === 'function'));
    callback(element, positional, named);
  }
  var _default = (0, _emberModifier.modifier)(gMapDidInsert, {
    eager: false
  });
  _exports.default = _default;
});